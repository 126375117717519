import {acceptHMRUpdate, defineStore} from "pinia"

export const useGlobalStore = defineStore("global", {
  state: () => ({
    isLoadingAccount: false,
    isPending: false,
    loadingNotify: {
      show: false,
      title: "Loading",
      text: "One moment please...",
    },
    notifications: [],
    dialog: {
      show: false,
      type: "",
      title: "",
      text: "",
      subtext: "",
      dismiss: {},
      action: {},
    },
    banner: {
      type: "info",
      text: "Your profile is incomplete.",
      show: false,
      action: {},
    },
  }),
  actions: {
    openDialog(
      type,
      title,
      text,
      action = {
        name: "",
        action: Function,
      },
      dismiss = {
        name: "",
        action: Function,
      },
      subtext = ""
    ) {
      this.dialog.show = true
      this.dialog.type = type
      this.dialog.title = title
      this.dialog.text = text
      this.dialog.action = action
      this.dialog.dismiss = dismiss
      this.dialog.subtext = subtext
    },

    openBanner(type, text, action = {label: "", action: Function}) {
      this.banner.show = true
      this.banner.type = type
      this.banner.text = text
      this.banner.action = action
    },

    async confirmDialog() {
      try {
        await this.dialog.action.action()
      } catch (e) {
        throw new Error(e)
      } finally {
        await this.closeDialog()
      }
    },

    async dismissDialog() {
      try {
        if (this.dialog.dismiss.action) {
          await this.dialog.dismiss.action()
        }
      } catch (e) {
        throw new Error(e)
      } finally {
        await this.closeDialog()
      }
    },

    async closeDialog() {
      this.dialog.show = false
      setTimeout(() => {
        this.dialog.action = {}
        this.dialog.dismiss = {}
      }, 200)
    },

    openNotify(type, title, text, timeout = 3500, delay = 500) {
      const now = Date.now()
      const recent = this.notifications.filter((n) => now - n.timestamp < delay)
      if (recent.some((n) => n.type === type && n.title === title && n.text === text)) {
        return null // Another identical notification already exists
      }
      const id = this.notifications?.length
      const notification = {
        id,
        type,
        title,
        text,
        timeout,
        show: true,
        close() {
          this.show = false
        },
      }
      notification.timestamp = now
      this.notifications.push(notification)

      return {notification, index: id}
    },

    closeNotify(index) {
      this.notifications[index].close()
    },

    closeBanner() {
      this.banner.show = false
    },

    validateInput(input) {
      // List of banned words
      const bannedWords = [
        "4r5e",
        "5h1t",
        "5hit",
        "a55",
        "anal",
        "anus",
        "ar5e",
        "arrse",
        "arse",
        "ass",
        "ass-fucker",
        "asses",
        "assfucker",
        "assfukka",
        "asshole",
        "assholes",
        "asswhole",
        "a_s_s",
        "b!tch",
        "b00bs",
        "b17ch",
        "b1tch",
        "ballbag",
        "balls",
        "ballsack",
        "bastard",
        "beastial",
        "beastiality",
        "bellend",
        "bestial",
        "bestiality",
        "bi+ch",
        "biatch",
        "bitch",
        "bitcher",
        "bitchers",
        "bitches",
        "bitchin",
        "bitching",
        "bloody",
        "blow job",
        "blowjob",
        "blowjobs",
        "boiolas",
        "bollock",
        "bollok",
        "boner",
        "boob",
        "boobs",
        "booobs",
        "boooobs",
        "booooobs",
        "booooooobs",
        "breasts",
        "buceta",
        "bugger",
        "bum",
        "bunny fucker",
        "butt",
        "butthole",
        "buttmuch",
        "buttplug",
        "c0ck",
        "c0cksucker",
        "carpet muncher",
        "cawk",
        "chink",
        "cipa",
        "cl1t",
        "clit",
        "clitoris",
        "clits",
        "cnut",
        "cock",
        "cock-sucker",
        "cockface",
        "cockhead",
        "cockmunch",
        "cockmuncher",
        "cocks",
        "cocksuck",
        "cocksucked",
        "cocksucker",
        "cocksucking",
        "cocksucks",
        "cocksuka",
        "cocksukka",
        "cok",
        "cokmuncher",
        "coksucka",
        "coon",
        "cox",
        "crap",
        "cum",
        "cummer",
        "cumming",
        "cums",
        "cumshot",
        "cunilingus",
        "cunillingus",
        "cunnilingus",
        "cunt",
        "cuntlick",
        "cuntlicker",
        "cuntlicking",
        "cunts",
        "cyalis",
        "cyberfuc",
        "cyberfuck",
        "cyberfucked",
        "cyberfucker",
        "cyberfuckers",
        "cyberfucking",
        "d1ck",
        "damn",
        "dick",
        "dickhead",
        "dildo",
        "dildos",
        "dink",
        "dinks",
        "dirsa",
        "dlck",
        "dog-fucker",
        "doggin",
        "dogging",
        "donkeyribber",
        "doosh",
        "duche",
        "dyke",
        "ejaculate",
        "ejaculated",
        "ejaculates",
        "ejaculating",
        "ejaculatings",
        "ejaculation",
        "ejakulate",
        "f u c k",
        "f u c k e r",
        "f4nny",
        "fag",
        "fagging",
        "faggitt",
        "faggot",
        "faggs",
        "fagot",
        "fagots",
        "fags",
        "fanny",
        "fannyflaps",
        "fannyfucker",
        "fanyy",
        "fatass",
        "fcuk",
        "fcuker",
        "fcuking",
        "feck",
        "fecker",
        "felching",
        "fellate",
        "fellatio",
        "fingerfuck",
        "fingerfucked",
        "fingerfucker",
        "fingerfuckers",
        "fingerfucking",
        "fingerfucks",
        "fistfuck",
        "fistfucked",
        "fistfucker",
        "fistfuckers",
        "fistfucking",
        "fistfuckings",
        "fistfucks",
        "flange",
        "fook",
        "fooker",
        "fuck",
        "fucka",
        "fucked",
        "fucker",
        "fuckers",
        "fuckhead",
        "fuckheads",
        "fuckin",
        "fucking",
        "fuckings",
        "fuckingshitmotherfucker",
        "fuckme",
        "fucks",
        "fuckwhit",
        "fuckwit",
        "fudge packer",
        "fudgepacker",
        "fuk",
        "fuker",
        "fukker",
        "fukkin",
        "fuks",
        "fukwhit",
        "fukwit",
        "fux",
        "fux0r",
        "f_u_c_k",
        "gangbang",
        "gangbanged",
        "gangbangs",
        "gaylord",
        "gaysex",
        "goatse",
        "God",
        "god-dam",
        "god-damned",
        "goddamn",
        "goddamned",
        "hardcoresex",
        "hell",
        "heshe",
        "hoar",
        "hoare",
        "hoer",
        "homo",
        "hore",
        "horniest",
        "horny",
        "hotsex",
        "jack-off",
        "jackoff",
        "jap",
        "jerk-off",
        "jism",
        "jiz",
        "jizm",
        "jizz",
        "kawk",
        "knob",
        "knobead",
        "knobed",
        "knobend",
        "knobhead",
        "knobjocky",
        "knobjokey",
        "kock",
        "kondum",
        "kondums",
        "kum",
        "kummer",
        "kumming",
        "kums",
        "kunilingus",
        "l3i+ch",
        "l3itch",
        "labia",
        "lust",
        "lusting",
        "m0f0",
        "m0fo",
        "m45terbate",
        "ma5terb8",
        "ma5terbate",
        "masochist",
        "master-bate",
        "masterb8",
        "masterbat*",
        "masterbat3",
        "masterbate",
        "masterbation",
        "masterbations",
        "masturbate",
        "mo-fo",
        "mof0",
        "mofo",
        "mothafuck",
        "mothafucka",
        "mothafuckas",
        "mothafuckaz",
        "mothafucked",
        "mothafucker",
        "mothafuckers",
        "mothafuckin",
        "mothafucking",
        "mothafuckings",
        "mothafucks",
        "mother fucker",
        "motherfuck",
        "motherfucked",
        "motherfucker",
        "motherfuckers",
        "motherfuckin",
        "motherfucking",
        "motherfuckings",
        "motherfuckka",
        "motherfucks",
        "muff",
        "mutha",
        "muthafecker",
        "muthafuckker",
        "muther",
        "mutherfucker",
        "n1gga",
        "n1gger",
        "nazi",
        "nigg3r",
        "nigg4h",
        "nigga",
        "niggah",
        "niggas",
        "niggaz",
        "nigger",
        "niggers",
        "nob",
        "nob jokey",
        "nobhead",
        "nobjocky",
        "nobjokey",
        "numbnuts",
        "nutsack",
        "orgasim",
        "orgasims",
        "orgasm",
        "orgasms",
        "p0rn",
        "pawn",
        "pecker",
        "penis",
        "penisfucker",
        "phonesex",
        "phuck",
        "phuk",
        "phuked",
        "phuking",
        "phukked",
        "phukking",
        "phuks",
        "phuq",
        "pigfucker",
        "pimpis",
        "piss",
        "pissed",
        "pisser",
        "pissers",
        "pisses",
        "pissflaps",
        "pissin",
        "pissing",
        "pissoff",
        "poop",
        "porn",
        "porno",
        "pornography",
        "pornos",
        "prick",
        "pricks",
        "pron",
        "pube",
        "pusse",
        "pussi",
        "pussies",
        "pussy",
        "pussys",
        "rectum",
        "retard",
        "rimjaw",
        "rimming",
        "s hit",
        "s.o.b.",
        "sadist",
        "schlong",
        "screwing",
        "scroat",
        "scrote",
        "scrotum",
        "semen",
        "sex",
        "sh!+",
        "sh!t",
        "sh1t",
        "shag",
        "shagger",
        "shaggin",
        "shagging",
        "shemale",
        "shi+",
        "shit",
        "shitdick",
        "shite",
        "shited",
        "shitey",
        "shitfuck",
        "shitfull",
        "shithead",
        "shiting",
        "shitings",
        "shits",
        "shitted",
        "shitter",
        "shitters",
        "shitting",
        "shittings",
        "shitty",
        "skank",
        "slut",
        "sluts",
        "smegma",
        "smut",
        "snatch",
        "son-of-a-bitch",
        "spac",
        "spunk",
        "s_h_i_t",
        "t1tt1e5",
        "t1tties",
        "teets",
        "teez",
        "testical",
        "testicle",
        "tit",
        "titfuck",
        "tits",
        "titt",
        "tittie5",
        "tittiefucker",
        "titties",
        "tittyfuck",
        "tittywank",
        "titwank",
        "tosser",
        "turd",
        "tw4t",
        "twat",
        "twathead",
        "twatty",
        "twunt",
        "twunter",
        "v14gra",
        "v1gra",
        "vagina",
        "viagra",
        "vulva",
        "w00se",
        "wang",
        "wank",
        "wanker",
        "wanky",
        "whoar",
        "whore",
        "willies",
        "willy",
        "xrated",
        "xxx",
      ]
      // Sanitize input by removing leading/trailing spaces and converting to lowercase
      const sanitizedInput = input.trim().toLowerCase()
      // Split input into words
      const words = sanitizedInput.split(" ")

      for (let i = 0; i < words.length; i++) {
        const word = words[i]
        if (bannedWords.includes(word)) {
          // Found banned word, reject input
          return false
        }
      }
      // Input is valid
      return true
    },
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useGlobalStore, import.meta.hot))
}
