import {DateTime} from "luxon"
import {computed, ref} from "vue"
import {useRouter} from "vue-router"
import {deleteObject} from "firebase/storage"
import {fbDoc, firebaseAuth} from "@/apis/firebase_config.js"
import {defaultLineItems} from "@/utils/index.js"
import {acceptHMRUpdate, defineStore} from "pinia"
import {useUserStore} from "@/stores/useUserStore.js"
import {useGlobalStore} from "@/stores/useGlobalStore.js"
import {firebaseDb, getFirebaseData, setFirebaseData, storage, storageRef} from "@/apis/index.js"
import {updateLaborAndProductsToV4, updateLeadToV4} from "@/utils/updateEstimateV3ToV4.js"
import {collection, getDoc, getDocs, query, where} from "firebase/firestore"

export const useResourceStore = defineStore("resource", () => {
  // Utils
  const router = useRouter()

  // Pinia Stores
  const userStore = useUserStore()
  const globalStore = useGlobalStore()

  // State
  const attachments = ref(new Map())
  const emailTemplates = ref(new Map())
  const lineItems = ref(new Map())
  const taxItems = ref(new Map())
  const scopeItems = ref(new Map())
  const leads = ref(new Map())
  const events = ref(new Map())
  const todaysEvents = ref(new Map())
  const selectedDate = ref(DateTime.local().toFormat("yyyy-MM-dd"))
  const fetchedEventDates = ref(new Map())
  const hasFetchedLeads = ref(false)

  // Getters
  const sortedAttachments = computed(() => {
    const array = Array.from(attachments.value.values())
    return array.sort((a, b) => {
      return a.title.localeCompare(b.title)
    })
  })

  const sortedLeads = computed(() => {
    let array = Array.from(leads.value.values())
    return array.sort((a, b) => {
      return b?.createdAt - a?.createdAt
    })
  })

  const lineItemsByType = computed(() => {
    const array = Array.from(lineItems.value.values())
    const sortedArray = array.sort((a, b) => {
      return a.label.localeCompare(b.label)
    })

    const labor = sortedArray.filter((item) => item.type === "labor")
    const product = sortedArray.filter((item) => item.type === "product")
    return {
      labor,
      product,
    }
  })

  const sortedEmailTemplates = computed(() => {
    const templates = Array.from(emailTemplates.value.values())
    return templates.sort((a, b) => a.title.localeCompare(b.title))
  })

  const sortedScope = computed(() => {
    const array = Array.from(scopeItems.value.values())
    return array.sort((a, b) => {
      return a.label.localeCompare(b.label)
    })
  })

  const currentMonthEvents = computed(() => {
    let array = Array.from(events.value.values())
    array = array.sort((a, b) => {
      const dateA = new Date(a.createdAt).getTime()
      const dateB = new Date(b.createdAt).getTime()
      return dateB - dateA
    })
    // Filter the estimates created within the current month
    const currentMonth = new Date().getMonth()
    const currentYear = new Date().getFullYear()
    return array.filter((estimate) => {
      const eventDate = new Date(estimate.createdAt)
      const eventMonth = eventDate.getMonth()
      const eventYear = eventDate.getFullYear()
      return eventMonth === currentMonth && eventYear === currentYear
    })
  })

  // Actions
  function fetchAttachments(uid) {
    if (!uid) throw new Error("User ID is required")

    const docRef = fbDoc(firebaseDb, "attachments", uid)

    getDoc(docRef)
      .then((docSnap) => {
        if (docSnap.exists()) {
          const data = docSnap.data()
          attachments.value = new Map(Object.entries(data || {}))
        }
      })
      .catch((error) => {
        console.error("Error fetching attachments:", error)
      })
  }

  function fetchEmailTemplates(uid) {
    if (!uid) throw new Error("User ID is required")

    const docRef = fbDoc(firebaseDb, "email_templates", uid)

    getDoc(docRef)
      .then((docSnap) => {
        if (docSnap.exists()) {
          const data = docSnap.data()
          emailTemplates.value = new Map(Object.entries(data || {}))
        }
      })
      .catch((error) => {
        throw new Error(error)
      })
  }

  function fetchTaxItems(uid) {
    if (!uid) throw new Error("User ID is required")

    const docRef = fbDoc(firebaseDb, "taxes", uid)

    getDoc(docRef)
      .then((docSnap) => {
        if (docSnap.exists()) {
          const data = docSnap.data()
          taxItems.value = new Map(Object.entries(data || {}))
        }
      })
      .catch((error) => {
        throw new Error(error)
      })
  }

  function fetchLineItems(uid) {
    if (!uid && firebaseAuth.currentUser) throw new Error("User ID is required")

    const docRef = fbDoc(firebaseDb, "line_items", uid)

    getDoc(docRef)
      .then((docSnap) => {
        if (docSnap.exists()) {
          const data = docSnap.data()
          const array = Object.values(data || {})
          const sortedArray = array.sort((a, b) => a.label.localeCompare(b.label))
          sortedArray.forEach((item) => {
            lineItems.value.set(item.key, item)
          })
        } else {
          return updateLineItemToNewVersion(uid)
        }
      })
      .catch((error) => {
        throw new Error(error)
      })
  }

  function fetchScopeItems(uid) {
    if (!uid) throw new Error("User ID is required")
    if (scopeItems.value.size) return Promise.resolve(sortedScope.value) // Return a resolved promise with an empty array if no update is needed

    const docRef = fbDoc(firebaseDb, "scope_of_work", uid)

    // Explicitly return the promise
    return getDoc(docRef)
      .then((docSnap) => {
        if (docSnap.exists()) {
          const data = docSnap.data()
          const array = Object.values(data || {})
          const sortedArray = array.sort((a, b) => a.label.localeCompare(b.label))
          sortedArray.forEach((item) => {
            scopeItems.value.set(item.key, item)
          })
          return sortedArray // Ensure sortedArray is returned here
        }
        return [] // Return an empty array if the document does not exist
      })
      .catch((error) => {
        throw new Error(error)
      })
  }

  async function updateLineItemToNewVersion(uid) {
    const labor = await getFirebaseData("labor", uid)
    const products = await getFirebaseData("products", uid)
    try {
      if (labor?.data || products?.data) {
        lineItems.value = await updateLaborAndProductsToV4(labor?.data || {}, products?.data || {})
        let newObject = {}
        lineItems.value.forEach((value, key) => {
          newObject[key] = value
        })
        await setFirebaseData("line_items", uid, newObject)
      } else {
        const newItems = defaultLineItems
        await setFirebaseData("line_items", uid, newItems)
        const array = Object.values(newItems || {})
        const sortedArray = array.sort((a, b) => a.label.localeCompare(b.label))
        sortedArray.forEach((item) => {
          lineItems.value.set(item.key, item)
        })
      }
    } catch (e) {
      console.error(e)
    }
  }

  function fetchLeads(uid) {
    if (!uid) throw new Error("User ID is required")
    if (hasFetchedLeads.value) return

    const q = query(collection(firebaseDb, "leads"), where("company.id", "==", uid))

    getDocs(q)
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          const data = doc.data()
          leads.value.set(data.id, updateLeadToV4(data))
        })
        hasFetchedLeads.value = true
      })
      .catch((e) => {
        console.error("Error fetching leads: " + e)
      })
  }

  function fetchTodaysEvents(uid) {
    if (!uid) throw new Error("User ID is required")
    const today = new Date().toISOString().slice(0, 10)

    const q = query(
      collection(firebaseDb, "events"),
      where("uid", "==", uid),
      where("dates", "array-contains", today)
    )

    getDocs(q)
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          const data = doc.data()
          events.value.set(data.id, data)
          todaysEvents.value.set(data.id, data)
        })
      })
      .catch((e) => {
        console.error("Error fetching todays events: " + e)
      })
  }

  function handleCreateEvent() {
    if (userStore.isPremium || currentMonthEvents.value.length < 3) {
      return true
    } else {
      return globalStore.openDialog(
        "warning",
        "Unlock Scheduling",
        "Premium users can access the calendar and quick event scheduling features.",
        {
          name: "View Plans",
          action: () => router.push("settings/Subscription"),
        }
      )
    }
  }

  function cloneLineItems() {
    const labor = lineItemsByType.value.labor
    const product = lineItemsByType.value.product
    // Reset Labor Qty
    labor.map((item) => {
      item.qty = 0
      return item
    })
    // Reset Product Qty
    product.map((item) => {
      item.qty = 0
      return item
    })
    return {
      labor,
      product,
    }
  }

  async function deleteFileFromStorage(path) {
    try {
      const docRef = storageRef(storage, path)
      await deleteObject(docRef)
    } catch (e) {
      throw new Error(e)
    }
  }

  function $reset() {
    attachments.value = new Map()
    emailTemplates.value = new Map()
    lineItems.value = new Map()
    taxItems.value = new Map()
    scopeItems.value = new Map()
    leads.value = new Map()
    events.value = new Map()
    todaysEvents.value = new Map()
    selectedDate.value = DateTime.local().toFormat("yyyy-MM-dd")
    fetchedEventDates.value = new Map()
    hasFetchedLeads.value = false
  }

  return {
    leads,
    events,
    taxItems,
    lineItems,
    scopeItems,
    sortedScope,
    attachments,
    sortedLeads,
    todaysEvents,
    selectedDate,
    emailTemplates,
    sortedAttachments,
    sortedEmailTemplates,
    fetchedEventDates,
    lineItemsByType,
    currentMonthEvents,
    fetchLeads,
    fetchTaxItems,
    fetchLineItems,
    fetchScopeItems,
    fetchAttachments,
    fetchTodaysEvents,
    fetchEmailTemplates,
    cloneLineItems,
    handleCreateEvent,
    deleteFileFromStorage,
    $reset,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useResourceStore, import.meta.hot))
}
