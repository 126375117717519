import {DateTime} from "luxon"
import {Browser} from "@capacitor/browser"
import {Capacitor} from "@capacitor/core"
import {EmailComposer} from "capacitor-email-composer"
import {SmsManager} from "@byteowls/capacitor-sms"

/**
 *  Formats a given date according to the specified format.
 *
 *  @param {number, string, object} date - The date to be formatted.
 *  @param {string} format - The format to be applied to the date.
 *  @returns {string} The formatted date.
 */

export const useFormatDate = (date, format = "long") => {
  try {
    // check if date is a timestamp, date object or string and format accordingly
    let d
    if (typeof date === "number") {
      d = date.toString().length > 10 ? DateTime.fromMillis(date) : DateTime.fromSeconds(date)
    } else if (date instanceof Date) {
      d = DateTime.fromJSDate(date)
    } else if (typeof date === "string") {
      // Modify the date string to match ISO 8601 format
      d = DateTime.fromISO(date, {zone: "local"})
    } else {
      throw new Error("Invalid date format.")
    }
    const now = DateTime.local()
    const diff = now
      .diff(d, ["years", "months", "weeks", "days", "hours", "minutes", "seconds"])
      .toObject()

    switch (format) {
      case "full":
        return d.toLocaleString(DateTime.DATETIME_FULL)
      case "long":
        return d.toLocaleString(DateTime.DATE_FULL)
      case "medium":
        return d.toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY)
      case "short":
        return d.toLocaleString(DateTime.DATE_SHORT)
      case "monthDay":
        return d.toLocaleString(DateTime.DATE_MED)
      case "time":
        return d.toLocaleString(DateTime.TIME_SIMPLE)
      case "ago":
        if (diff.years > 0) {
          // return `${diff.years} yr.${diff.years > 1 ? 's' : ''} ago`
          return d.toLocaleString(DateTime.DATE_SHORT)
        } else if (diff.months > 0) {
          // return `${diff.months} mo.${diff.months > 1 ? 's' : ''} ago`
          return d.toLocaleString(DateTime.DATE_SHORT)
        } else if (diff.weeks > 0) {
          return `${diff.weeks} week${diff.weeks > 1 ? "s" : ""} ago`
        } else if (diff.days > 0) {
          return `${diff.days} day${diff.days > 1 ? "s" : ""} ago`
        } else if (diff.hours > 0) {
          return `${diff.hours} hour${diff.hours > 1 ? "s" : ""} ago`
        } else if (diff.minutes > 0) {
          return `${diff.minutes} minute${diff.minutes > 1 ? "s" : ""} ago`
        } else {
          return "Just now"
        }
      default:
        throw new Error(`Invalid format: ${format}.`)
    }
  } catch (error) {
    console.error(`Error formatting date ${date} in format ${format}:`, error)
    return ""
  }
}

/**
 *  Converts a number to a formatted currency string.
 *
 *  @param {number} val - The number to format.
 *  @param {string} currencyCode - The currency code to use for formatting (e.g. "USD", "EUR", "JPY").
 *  @param {string} locale - The locale to use for formatting (e.g. "en-US", "fr-FR", "ja-JP").
 *  @param {number} decimalPlaces - amount of decimal places
 *  @returns {string} - The formatted currency string.
 */
export const useFormatCurrency = (val, decimalPlaces, currencyCode, locale = undefined) => {
  decimalPlaces = decimalPlaces ? decimalPlaces : decimalPlaces === 0 ? 0 : 2
  currencyCode = currencyCode || "USD"
  const userLocale = navigator.language || navigator.userLanguage || "en-US"

  const currencySymbolMap = {
    USD: "$",
    EUR: "€",
    JPY: "¥",
    GBP: "£",
    CHF: "CHF",
    CAD: "CA$",
    AUD: "A$",
    NZD: "NZ$",
    CNY: "¥",
    HKD: "HK$",
    MXN: "MXN$", // Add Mexican peso (MXN) symbol
  }

  const defaultLocale = locale || userLocale

  const currencySymbol = currencySymbolMap[currencyCode] || "$" // Default to USD symbol
  const formatOptions = {
    style: "currency",
    currency: currencyCode,
    currencyDisplay: "symbol",
    minimumFractionDigits: decimalPlaces,
    maximumFractionDigits: decimalPlaces,
  }

  return new Intl.NumberFormat(defaultLocale, formatOptions)
    .format(val)
    .replace(currencySymbolMap[currencyCode], currencySymbol)
}

/**
 * Toggles an item in an array. If the item is present, it will be removed. If not, it will be added.
 *
 * @param {Array} array - The array to toggle the item in.
 * @param {*} val - The value to toggle in the array.
 */
export function useToggleArrayItem(array, val) {
  let index = null
  if (typeof val === "object") {
    const newVal = val?.name || val?.val || val?.value || val?.id || val?.uid || val?.key
    index = array.findIndex(
      (e) => (e.name || e.val || e.value || e.id || e?.uid || e?.key || e) === newVal
    )

    if (index > -1) {
      // console.log('delete', array[index]);
      array.splice(index, 1)
    } else {
      // console.log('add', val);
      array.push(val)
    }
  } else {
    index = array.indexOf(val)
    if (index > -1) {
      // console.log('delete', array[index]);
      array.splice(index, 1)
    } else {
      // console.log('add', val);
      array.push(val)
    }
  }
  return array
}

/**
 *  Returns the initials of a given name as a string of uppercase letters.
 *
 *  @param {string} name - The name to get the initials from.
 *  @returns {string} - The initials of the given name in uppercase letters.
 */
export function useGetInitials(name) {
  if (typeof name === "string" && name.trim() !== "") {
    const initials = name.match(/\b\w/g) || []
    return initials.join("").toUpperCase()
  } else {
    return ""
  }
}

/**
 * Scrolls to the element with the provided class, id or element.
 *
 * @param {string | HTMLElement} target - The class, id or element to scroll to.
 * @param {ScrollBehavior | undefined} [behavior='smooth'] - The scrolling behavior to use.
 */
export function useScrollTo(target, behavior = "smooth") {
  let el
  if (typeof target === "string") {
    el = document.querySelector(`.${target}`)
    if (!el) {
      el = document.querySelector(`#${target}`)
    }
  } else if (target instanceof HTMLElement) {
    el = target
  }
  if (el) {
    el.scrollIntoView({behavior})
  }
}

export function useRemToPx(remValue) {
  const remInPx = parseFloat(getComputedStyle(document.documentElement).fontSize)
  return remValue * remInPx
}

export async function useOpenEmailFromTemplate(url, template, type, to) {
  try {
    if (Capacitor.isNativePlatform() && Capacitor.getPlatform() === "android") {
      const subject = template?.subject || ""
      let body = template?.message || ""
      body += "\n\nView your " + type.toLowerCase() + ": " + url
      try {
        await EmailComposer.open({
          to: [to],
          subject: subject || " ",
          body: body || " ",
          isHtml: false,
        })
      } catch (e) {
        console.error(e)
      }
    } else {
      const subject = template?.subject ? encodeURIComponent(template.subject) : ""
      let body = template?.message ? encodeURIComponent(template.message) : ""
      body += "%0D%0A%0D%0A" + encodeURIComponent(`View your ${type.toLowerCase()}: ${url}`)
      const link = `mailto:${to}?subject=${subject}&body=${body}`
      if (Capacitor.isNativePlatform()) {
        window.location.href = link
      } else {
        await Browser.open({url: link, windowName: "_blank"})
      }
    }
  } catch (error) {
    console.error(error)
  }
}

export async function useOpenEmail(subject, body, to) {
  try {
    if (Capacitor.isNativePlatform() && Capacitor.getPlatform() === "android") {
      try {
        await EmailComposer.open({
          to: [to],
          subject: subject || " ",
          body: body || " ",
          isHtml: false,
        })
      } catch (e) {
        console.error(e)
      }
    } else {
      const link = `mailto:${to}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(
        body
      )}`
      if (Capacitor.isNativePlatform()) {
        window.location.href = link
      } else {
        await Browser.open({url: link})
      }
    }
  } catch (error) {
    console.error(error)
  }
}

export async function useOpenSMS(url, type, to) {
  try {
    const body = `View your ${type.toLowerCase()}: ${url}`
    if (Capacitor.isNativePlatform() && Capacitor.getPlatform() === "android") {
      await SmsManager.send({
        numbers: [to],
        text: body || " ",
      })
    } else {
      const encodedBody = encodeURIComponent(body) // Ensure the message is URL-encoded
      const link = `sms:+1${to}?body=${encodedBody}` // Correct format for SMS URI scheme

      if (Capacitor.isNativePlatform()) {
        window.location.href = link // Directly navigate for native platforms
      } else {
        await Browser.open({
          url: link,
          windowName: "_blank",
        }) // Use the Capacitor Browser plugin for web platforms
      }
    }
  } catch (error) {
    console.error(error)
  }
}

export async function useOpenTextMessage(body, to) {
  try {
    if (Capacitor.isNativePlatform() && Capacitor.getPlatform() === "android") {
      await SmsManager.send({
        numbers: to,
        text: body || " ",
      })
    } else {
      const encodedBody = encodeURIComponent(body) // Ensure the message is URL-encoded
      const link = `sms:+1${to}?body=${encodedBody}` // Correct format for SMS URI scheme

      if (Capacitor.isNativePlatform()) {
        window.location.href = link // Directly navigate for native platforms
      } else {
        await Browser.open({
          url: link,
        }) // Use the Capacitor Browser plugin for web platforms
      }
    }
  } catch (error) {
    console.error(error)
  }
}

// function capitalize(word) {
//   return word.charAt(0).toUpperCase() + word.slice(1)
// }

export function useSafeUrl(str) {
  if (str) {
    return str
      .trim() // Remove leading/trailing white space
      .replace(/(^[^a-z0-9]+)|([^a-z0-9-.]+$)/gi, "") // Remove non-alphanumeric characters at the beginning or end
      .toLowerCase() // Convert to lowercase
      .replace(/([^a-z0-9-])/g, "-") // Replace non-alphanumeric characters with a single hyphen
      .replace(/(-+)/g, "-") // Replace consecutive hyphens with a single hyphen
  }
}

export function useGetNewUrl(originalUrl, masterUrlArray) {
  const safeUrl = useSafeUrl(originalUrl)

  if (masterUrlArray.includes(safeUrl)) {
    let i = 2
    while (masterUrlArray.includes(`${safeUrl}-${i}`)) {
      i++
    }
    return `${safeUrl}-${i}`
  } else {
    return safeUrl
  }
}

export function useGetYearFromTimestamp(unixTimestamp) {
  const date = new Date(unixTimestamp)
  return date.getFullYear()
}

export function documentExpireDate(data, type) {
  let currentDate = new Date(data.createdAt)
  let futureDate = new Date(data.createdAt)
  if (type?.toLowerCase() === "estimate") {
    futureDate.setDate(currentDate.getDate() + data?.days_valid || 30)
  } else {
    const days = data.days_until_due !== undefined ? data?.days_until_due : 30
    futureDate.setDate(currentDate.getDate() + days)
  }
  return futureDate
}

export function isPastDueOrExpired(data, type) {
  const status = data?.status.toLowerCase()
  const currentDate = new Date()
  const expirationDate = documentExpireDate(data, type)

  // The item is past due or expired if the current date is after the expiration date
  return ["pending", "open"].includes(status) && currentDate > expirationDate
}
