import {useUid} from "@/utils/useUid.js"
import {company as companyRef, lineItem as lineItemRef} from "@/utils/constants.js"
import {capitalize} from "lodash"

const rate = {
  sqft: {
    name: "Sqft",
    type: "$",
    unit: "sqft",
    key: "sqft",
  },
  colorIncrease: {
    name: "Color Increase",
    type: "%",
    unit: "sqft",
    key: "colors",
  },
  colors: {
    name: "Colors",
    type: "%",
    unit: "sqft",
    key: "colors",
  },
  condition: {
    name: "Condition",
    type: "%",
    unit: "sqft",
    key: "condition",
  },
  difficulty: {
    name: "Difficulty",
    type: "%",
    unit: "sqft",
    key: "difficulty",
  },
  ceiling: {
    name: "Ceiling",
    type: "$",
    unit: "sqft",
    key: "ceiling",
  },
  trim: {
    name: "Trim",
    type: "$",
    unit: "sqft",
    key: "trim",
  },
  door: {
    name: "Door",
    type: "$",
    unit: "count",
    key: "door",
  },
  doorSide: {
    name: "Door per side",
    type: "$",
    unit: "count",
    key: "doorSide",
  },
  doorGarage: {
    name: "Garage Door",
    type: "$",
    unit: "count",
    key: "doorGarage",
  },
  window: {
    name: "Window",
    type: "$",
    unit: "count",
    key: "window",
  },
  windowSash: {
    name: "Window Sash",
    type: "$",
    unit: "count",
    key: "windowSash",
  },
  sash: {
    name: "Window Sash",
    type: "$",
    unit: "count",
    key: "windowSash",
  },
  shutter: {
    name: "Shutter",
    type: "$",
    unit: "count",
    key: "shutter",
  },
  feature: {
    name: "Feature",
    type: "$",
    unit: "count",
    key: "feature",
  },
}

const templateName = {
  ext: "Exterior Painting",
  int: "Interior Painting",
  extNew: "Exterior Painting Alt",
  intNew: "Interior Painting Alt",
}

const templateType = {
  ext: "exterior",
  int: "interior",
  extNew: "exterior",
  intNew: "interior",
}

function updateCompanyDataToV4(company) {
  if (company.version !== "v4") {
    const newProduction = {}

    for (const [key, value] of Object.entries(company.production)) {
      if (!value.key) {
        delete value?.show
        try {
          const production_rates = Object.entries(value).map(([id, val]) => {
            if (isNaN(parseInt(id))) {
              return {
                name: rate[id].name,
                value: parseFloat(val),
                type: rate[id].type,
                unit: rate[id].unit,
                qty: 0,
              }
            }
          })

          newProduction[key] = {
            key: key,
            name: templateName[key],
            production_rates,
            type: templateType[key],
          }
        } catch (e) {
          throw new Error(e)
        }
      } else {
        newProduction[key] = value
      }
    }

    company.production = newProduction
    company.directory = updateDirectory(company.directory)
    company.contact = {
      ...company.contact,
      yelp: company.contact.yelp || "",
      instagram: company.contact.instagram || "",
      facebook: company.contact.facebook || "",
      tradeBox: "",
      showYelpReviews: true,
      businessHours: {
        days: "Week days",
        hours: "9am - 5pm",
      },
    }
    company = {...companyRef, ...company}
  }

  return company
}

function updateDirectory(directory) {
  return {
    established: directory.established || directory.yearStart || new Date().getFullYear(),
    structure: directory.structure || directory.type?.toLowerCase() || "",
    services: directory.services || directory.services || [],
    pay_rates: directory.pay_rates || directory.rates || [],
    status: directory.status || directory.hiring ? "hiring" : directory.looking ? "for hire" : "",
    positions: directory.positions || directory?.desc || directory?.positionDesc || [],
    employment: directory.employment || directory.positionTime || "",
    isPublic: directory.isPublic || directory.showInDirectory || false,
  }
}

function updateEstimateV3ToV4(item) {
  const type = item.type.includes("exterior")
    ? "exterior"
    : item.type.includes("interior")
      ? "interior"
      : "general"

  const newEst = {
    ...item,
    reference_number: item.estimateRef || 0,
    type,
    template: updateProductionToTemplate(
      item.production,
      item.type,
      item.details,
      item.totalSqft
    ) || {production_rates: []},
    rooms: updateRooms(item.rooms, item.production),
    status: updateStatus(item),
    labor: updateLaborOrProductsArrayToV4(item.labor, "labor"),
    products: updateLaborOrProductsArrayToV4(item.products, "product"),
    attachments: updateAttachmentsV4(item.attachments) || [],
    photos: updatePhotos(item?.pictures || [], item.createdAt),
    version: "v4",
  }

  if (type === "exterior") {
    newEst.measurements = newEst.measurements.map((item) => `${item.length}*${item.width}`)
  }

  newEst.template.production_rates = newEst.template.production_rates.filter((productionRate) => {
    return (
      productionRate !== undefined &&
      !newEst.labor.some(
        (laborItem) =>
          laborItem?.key === productionRate?.key || laborItem?.label === productionRate?.name
      )
    )
  })

  delete newEst.details
  delete newEst.production
  return newEst
}

function updateProductionToTemplate(production, type, detailsArray, totalSqft) {
  if (production && !production.key) {
    delete production?.show

    const production_rates = Object.entries(production).map(([id, val]) => {
      const matchingItem = detailsArray.find((item) => {
        const colorMatch = item.desc.toLowerCase() === id.replace("Increase", "s")
        return item.key === id || colorMatch
      })

      if (totalSqft && id === "sqft") {
        return {
          name: "Sqft",
          key: "sqft",
          type: "$",
          unit: "sqft",
          value: parseFloat(val),
          qty: totalSqft,
        }
      } else if (val) {
        return {
          name: rate[id].name,
          key: rate[id].key,
          value: parseFloat(val),
          type: rate[id].type,
          unit: rate[id].unit,
          qty: matchingItem?.key === "colors" ? matchingItem?.qty - 1 : matchingItem?.qty || 0,
        }
      }
    })

    const newType = type.includes("exterior")
      ? "exterior"
      : type.includes("interior")
        ? "interior"
        : "general"

    return {
      key: useUid(),
      name: type,
      production_rates,
      type: newType,
    }
  } else {
    return production
  }
}

/*function updateEmailTemplatesToV4(templates) {
  const array = Array.from(templates.values());
  const updatedMap = new Map();

  for (const item of array) {
    if (item.key === 'promotion') {
      item.key = 'promo'
    }
    item.key = item.key || useUid()
    updatedMap.set(item.key, item);
  }
  return updatedMap;
}*/

function updateLaborAndProductsToV4(labor, products) {
  const laborArray = Object.values(labor)
  const productArray = Object.values(products)
  const updatedMap = new Map()

  for (const item of laborArray) {
    const newItem = {
      ...lineItemRef,
      applyTax: [],
      key: item?.key,
      type: "labor",
      label: item?.desc,
      qty: 0,
      unit_amount: parseFloat(item?.cost),
    }
    delete newItem.desc
    updatedMap.set(newItem.key, newItem)
  }

  for (const item of productArray) {
    const newItem = {
      ...lineItemRef,
      applyTax: item.applyTax || [],
      key: item?.key,
      type: "product",
      label: item?.product,
      qty: 0,
      unit_amount_wholesale: parseFloat(item?.cost),
      unit_amount: parseFloat(item?.retail),
    }
    delete newItem.product
    updatedMap.set(newItem.key, newItem)
  }

  return updatedMap
}

function updateLaborOrProductsArrayToV4(array, type) {
  return array.map((item) => {
    return {
      ...lineItemRef,
      applyTax: item?.applyTax || [],
      key: item?.key || useUid(),
      type,
      label: item?.product || item?.desc || item?.key || "",
      qty: item?.qty || 0,
      unit_amount: item?.product ? parseFloat(item?.retail) : parseFloat(item?.cost),
    }
  })
}

function updateAttachmentsV4(attachments) {
  return attachments.map((item) => {
    return {
      key: useUid(),
      title: capitalize(item.key),
      ...item,
    }
  })
}

function updatePhotos(array, date) {
  const photos = {}
  for (const item of array) {
    const uid = useUid()
    photos[uid] = {
      key: uid,
      createdAt: date,
      url: item.url,
      storage_path: item.fullPath,
      description: "",
    }
  }
  return photos
}

function updateStatus(item) {
  const {status} = item
  let lowerStatus
  if (status?.status) {
    lowerStatus = status.status.toLowerCase()
  } else {
    lowerStatus = status.toLowerCase()
  }

  let newStatus = "draft"

  if (["sent", "pending"].includes(lowerStatus)) {
    newStatus = "pending"
  } else if (["active", "accepted", "approved"].includes(lowerStatus)) {
    newStatus = "accepted"
  } else if (lowerStatus === "complete") {
    newStatus = "complete"
  } else if (["declined", "canceled"].includes(lowerStatus)) {
    newStatus = "canceled"
  }

  return newStatus
}

/*function updateDetails(details, production, totalSqft) {
  console.log('details', details)
  const array = details.map(item => {
    return {
      name: item.desc,
      key: item.key,
      value: item.cost * 100,
      type: '%',
      unit: 'sqft',
      qty: item.qty
    }
  }) || []

  array.unshift({
    name: 'Sqft',
    key: 'sqft',
    value: parseFloat(production?.sqft) || 0,
    type: '$',
    unit: 'sqft',
    qty: totalSqft || 0
  })

  console.log('array should have sqft', array)
  return array || []
}*/

function updateRooms(rooms, production) {
  if (rooms && rooms.length) {
    return rooms?.map((room) => {
      const scope = {
        isWalls: room.isWalls || false,
        isDoors: room.isDoors || false,
        isCeiling: room.isCeiling || false,
        isTrim: room.isTrim || false,
        isCrown: room.isCrown || false,
      }

      delete room.isWalls
      delete room.isDoors
      delete room.isCeiling
      delete room.isTrim
      delete room.isCrown
      return {
        ...room,
        key: useUid(),
        counts: [
          {
            name: "Doors",
            key: "1",
            value: production.door,
            type: "$",
            unit: "count",
            qty: room.doors,
          },
          {
            name: "Windows",
            key: "2",
            value: production.window,
            type: "$",
            unit: "count",
            qty: room.windows,
          },
          {
            name: "Features",
            key: "3",
            value: production.feature,
            type: "$",
            unit: "count",
            qty: room.features,
          },
        ],
        scope,
      }
    })
  } else {
    return []
  }
}

function updateLeadToV4(item) {
  if (item.version !== "v4") {
    /*    const type = item?.interior || item?.scope?.includes('interior') ? 'interior' : item?.exterior || item?.scope?.includes('exterior') ? 'exterior' : 'general'*/
    const status = item.status?.status?.status
      ? item.status?.status?.status
      : item.status?.status
        ? item.status?.status
        : item.status?.emailed
          ? "contacted"
          : "pending"

    /* let stats
     if (type === 'exterior') {
       stats = {
         sqft: item?.totalSqft || 0,
         colors: item?.colors || 0,
         condition: item?.condition || 0,
         difficulty: item?.difficulty || 0,
         doors: item?.totalDoors || 0,
         windows: item?.totalWindows || 0,
         'window sashes': item?.totalWindowSashes || 0,
         'garage doors': item?.totalDoorGarage || 0
       };
     }

     if (type === 'interior') {
       stats = {
         rooms: item.roomCount,
         'ceiling sqft': item?.ceilingSqftTotal || 0,
         'wall sqft': item?.wallSqftTotal || 0,
         'floor sqft': item?.totalFloorSqft || 0
       };
     }*/

    /* const totals = {
       labor: item?.laborTotal || 0,
       material: item?.materialTotal || 0,
       subtotal: item?.subTotal || 0
     }*/

    return {
      createdAt: item.createdAt,
      id: item.id,
      company: item.company,
      contact: item.contact,
      message: item.message,
      status,
      /*   type,
         stats,
         totals,*/
      version: "v4",
    }
  } else {
    return item
  }
}

export {
  updateLeadToV4,
  updateCompanyDataToV4,
  updateLaborAndProductsToV4,
  updateLaborOrProductsArrayToV4,
  updateEstimateV3ToV4,
}
