// useRoute
import {createRouter, createWebHistory} from "vue-router"
import routes from "@/router/routes.js"
import {firebaseAuth} from "@/apis/index.js"
import {useUserStore} from "@/stores/useUserStore.js"
import {useStorage} from "@vueuse/core"
import {useAuthStore} from "@/stores/useAuthStore.js"

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return (
      savedPosition ||
      new Promise((resolve) => {
        setTimeout(() => resolve({top: 0}), 300)
      })
    )
  },
})

/*router.beforeEach(async (to, from, next) => {
  const userStore = useUserStore()
  const userUid = userStore.user?.uid
  const isAuthorized = !!firebaseAuth.currentUser && !!userUid
  console.log("firebaseAuth.currentUser", JSON.stringify(firebaseAuth.currentUser?.uid))
  console.log("firebaseAuth 🔥", !!firebaseAuth.currentUser)
  console.log("to 🔥", JSON.stringify(to))
  if (
    !to?.meta?.requiresAuth &&
    ( to.path === "/sign-up" || to.path === "/sign-in") &&
    isAuthorized
  ) {
    next({name: "Dashboard"})
  } else if (to?.meta?.requiresAuth && !isAuthorized) {
    next({name: "Sign up"})
  } else {
    next()
  }
})*/

router.beforeEach(async (to, from, next) => {
  const authStore = useAuthStore()
  const userStore = useUserStore()
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth)

  // Use the utility function to ensure auth state is confirmed
  const isAuthorized = authStore.isAuthorized && !!userStore?.user?.uid

  if (requiresAuth && !isAuthorized) {
    // User is not authenticated and trying to access a protected route
    next({name: "Sign up"})
  } else if (!requiresAuth && isAuthorized && (to.name === "Sign up" || to.name === "Sign in")) {
    // Authenticated user is trying to access an auth-related route
    next({name: "Dashboard"})
  } else {
    // Proceed as normal for all other cases
    next()
  }
})

export default router
