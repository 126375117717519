import {computed, ref} from "vue"
import {acceptHMRUpdate, defineStore} from "pinia"
import {firebaseDb, functions} from "@/apis/index.js"
import {invoice as invoiceRef} from "@/utils/index.js"
import {useUserStore} from "@/stores/useUserStore.js"
import {useContactStore} from "@/stores/useContactStore.js"
import {httpsCallable} from "firebase/functions"
import {useRouter} from "vue-router"
import {cloneDeep} from "lodash"
import {collection, onSnapshot, query, where} from "firebase/firestore"

export const useInvoiceStore = defineStore("invoice", () => {
  const router = useRouter()
  const userStore = useUserStore()
  const contactStore = useContactStore()

  // State
  const invoices = ref(new Map())
  const invoice = ref(cloneDeep(invoiceRef))
  const listeners = ref([])

  // Getters
  const invoiceAccountComplete = computed(() => {
    const connect = userStore.company.plan.stripe_connect
    return (
      userStore.isPremium &&
      connect &&
      connect.account_uid &&
      connect.details_submitted &&
      connect.charges_enabled
    )
  })

  /*const invoicesByStatus = computed(() => {
    let invoiceArray = Array.from(invoices.value.values())
    invoiceArray = invoiceArray.sort((a, b) => {
      const dateA = new Date(a.createdAt).getTime()
      const dateB = new Date(b.createdAt).getTime()
      return dateB - dateA
    })
    // Filter the invoices created within the current month
    const currentMonth = new Date().getMonth()
    const currentYear = new Date().getFullYear()
    const currentMonthInvoices = invoiceArray.filter((invoice) => {
      const invoiceDate = new Date(invoice.createdAt)
      const invoiceMonth = invoiceDate.getMonth()
      const invoiceYear = invoiceDate.getFullYear()
      return invoiceMonth === currentMonth && invoiceYear === currentYear
    })

    return {
      all: invoiceArray,
      currentMonth: currentMonthInvoices,
      draft: invoiceArray.filter((invoice) => invoice.status === "draft"),
      open: invoiceArray.filter((invoice) => invoice.status === "open"),
      paid: invoiceArray.filter((invoice) => invoice.status === "paid"),
      void: invoiceArray.filter((invoice) => invoice.status === "void"),
      uncollectible: invoiceArray.filter((invoice) => invoice.status === "uncollectible"),
    }
  })*/

  const invoicesByStatus = computed(() => {
    let invoiceArray = Array.from(invoices.value.values())
    invoiceArray = invoiceArray.sort((a, b) => {
      const dateA = new Date(a.createdAt).getTime()
      const dateB = new Date(b.createdAt).getTime()
      return dateB - dateA
    })

    return {
      all: invoiceArray,
      currentMonth: invoiceArray.filter((invoice) => {
        const invoiceDate = new Date(invoice.createdAt)
        return (
          invoiceDate.getMonth() === new Date().getMonth() &&
          invoiceDate.getFullYear() === new Date().getFullYear()
        )
      }),
      draft: invoiceArray.filter((invoice) => invoice.status === "draft"),
      open: invoiceArray.filter(
        (invoice) => invoice.status === "open" || invoice.status === "pending"
      ),
      paid: invoiceArray.filter((invoice) => invoice.status === "paid"),
      void: invoiceArray.filter((invoice) => invoice.status === "void"),
      uncollectible: invoiceArray.filter((invoice) => invoice.status === "uncollectible"),
      pastDue: invoiceArray.filter(
        (invoice) =>
          isPastDue(invoice) && (invoice.status === "open" || invoice.status === "pending")
      ),
    }
  })

  const dashboardStats = computed(() => {
    const open = invoicesByStatus.value.open
    const paid = invoicesByStatus.value.paid
    const pastDue = invoicesByStatus.value.pastDue

    const totalOpen = open.reduce((total, est) => total + est.total, 0)
    const totalPaid = paid.reduce((total, est) => total + est.total, 0)
    const totalPastDue = pastDue.reduce((total, est) => total + est.total, 0)

    return {
      open: totalOpen - totalPastDue,
      paid: totalPaid,
      pastDue: totalPastDue,
    }
  })

  // Actions
  function fetchInvoices(uid) {
    if (!uid) throw new Error("User ID is required")

    const q = query(collection(firebaseDb, "invoices"), where("company_id", "==", uid))
    const unsubscribe = onSnapshot(
      q,
      (querySnapshot) => {
        querySnapshot.forEach((doc) => {
          const data = doc.data()
          invoices.value.set(data.id, data)
        })
      },
      (error) => {
        console.error("Error fetching invoices:", error)
        // Handle the error appropriately. Maybe update the UI or state
      }
    )

    listeners.value.push(unsubscribe)
  }

  function isPastDue(invoice) {
    let dueDate = new Date(invoice.createdAt)
    const daysUntilDue = invoice.days_until_due !== undefined ? invoice.days_until_due : 30
    dueDate.setDate(dueDate.getDate() + daysUntilDue)
    return new Date() > dueDate // True if current date is after the due date
  }

  async function handleOpenInvoicing() {
    if (userStore.isPremium && invoiceAccountComplete.value) {
      await router.push({name: "Invoice Builder"})
      return true
    } else {
      await userStore.handleCreateConnectAccount()
      return false
    }
  }

  async function createStripeInvoice(user, company, invoice, action) {
    try {
      const client_stripe = await contactStore.assignClientStripeData(invoice.contact.contactId)
      if (client_stripe.id) invoice.contact.stripe = client_stripe
      const callable = httpsCallable(functions, "stripeCreateInvoice")
      await callable({
        invoice,
        company,
        action,
        isLive: userStore.isLive,
      })
    } catch (e) {
      throw new Error("error creating invoice: " + e)
    }
  }

  /**
   * @param contact
   * @return {Array} A specific client invoices
   */
  function fetchInvoicesForContact(contact) {
    let array = Array.from(invoices.value.values())
    array = array.filter((invoice) => {
      // Check if both email fields are not null or empty
      const emailConditionsMet =
        invoice.contact.email &&
        contact.email &&
        invoice.contact.email.trim() !== "" &&
        contact.email.trim() !== ""

      // Proceed with email comparison only if both emails have valid values
      if (emailConditionsMet) {
        return invoice.contact.email === contact.email
      } else {
        // If email comparison is not applicable, compare contact IDs
        return invoice.contact.contactId === contact.contactId
      }
    })

    return array.sort((a, b) => {
      const dateA = new Date(a.createdAt).getTime()
      const dateB = new Date(b.createdAt).getTime()
      return dateB - dateA // Sort in descending order by createdAt date
    })
  }

  function resetInvoices() {
    invoice.value = cloneDeep(invoiceRef)
  }

  function $reset() {
    invoices.value = new Map()
    listeners.value = []
    resetInvoices()
  }

  return {
    invoices,
    invoice,
    resetInvoices,
    fetchInvoicesForContact,
    handleOpenInvoicing,
    invoiceAccountComplete,
    createStripeInvoice,
    invoicesByStatus,
    dashboardStats,
    fetchInvoices,
    listeners,
    $reset,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useInvoiceStore, import.meta.hot))
}
