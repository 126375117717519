import {computed, ref} from "vue"
import {acceptHMRUpdate, defineStore} from "pinia"
import {firebaseDb} from "@/apis/index.js"
import {collection, getDocs, query, where} from "firebase/firestore"

export const useContactStore = defineStore("contacts", () => {
  // State
  const contacts = ref(new Map())

  // Getters
  const sortedContacts = computed(() => {
    const contactArray = Array.from(contacts.value.values())
    return contactArray.sort((a, b) => {
      return a.name.localeCompare(b.name)
    })
  })

  const currentMonthContacts = computed(() => {
    let array = Array.from(contacts.value.values())
    array = array.sort((a, b) => {
      const dateA = new Date(a.createdAt).getTime()
      const dateB = new Date(b.createdAt).getTime()
      return dateB - dateA
    })
    // Filter the estimates created within the current month
    const currentMonth = new Date().getMonth()
    const currentYear = new Date().getFullYear()
    return array.filter((estimate) => {
      const contactDate = new Date(estimate.createdAt)
      const contactMonth = contactDate.getMonth()
      const contactYear = contactDate.getFullYear()
      return contactMonth === currentMonth && contactYear === currentYear
    })
  })

  // Actions
  function fetchContacts(uid) {
    if (!uid) throw new Error("User ID is required")

    const q = query(collection(firebaseDb, "contacts"), where("uid", "==", uid))

    return getDocs(q)
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          const data = doc.data()
          data.stripe = data.stripe || {id: ""}
          contacts.value.set(data.contactId || data.id, data)
        })
      })
      .catch((e) => {
        throw new Error("Error fetching contacts: " + e)
      })
  }

  function $reset() {
    contacts.value = new Map()
  }

  async function assignClientStripeData(client_id) {
    const contact = contacts.value.get(client_id)
    console.log("client", contact)
    return contact.stripe || {id: ""}
  }

  return {
    $reset,
    contacts,
    sortedContacts,
    currentMonthContacts,
    assignClientStripeData,
    fetchContacts,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useContactStore, import.meta.hot))
}
