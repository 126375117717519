const routes = [
  // Unrestricted
  {
    path: "/sign-in",
    name: "Sign in",
    component: () => import("@/pages/Auth.vue"),
  },
  {
    path: "/sign-up",
    name: "Sign up",
    component: () => import("@/pages/Auth.vue"),
  },
  {
    path: "/privacy",
    name: "Privacy",
    component: () => import("@/pages/Privacy.vue"),
  },
  {
    path: "/terms",
    name: "Terms",
    component: () => import("@/pages/TermsOfService.vue"),
  },
  {
    path: "/stripe-connect",
    name: "Stripe Connect",
    component: () => import("@/pages/StripeConnect.vue"),
  },
  {
    path: "/u2/:id",
    name: "Client Est",
    params: true,
    meta: {
      head: {
        title: "Estimate",
        description: "Your estimate is ready",
        image:
          "https://firebasestorage.googleapis.com/v0/b/paintbox-v2.appspot.com/o/000_admin%2Fview_estimate.png?alt=media&token=ccfe11ee-cf1b-4d05-a449-47fd205f006a",
      },
    },
    component: () => import("@/pages/ClientDigitalEstimate.vue"),
  },
  {
    path: "/u3/:id",
    name: "Client Inv",
    params: true,
    meta: {
      head: {
        title: "Estimate",
        description: "Your estimate is ready",
        image:
          "https://firebasestorage.googleapis.com/v0/b/paintbox-v2.appspot.com/o/000_admin%2Fview_invoice.png?alt=media&token=265c27cc-a7e0-4b29-a210-dd162fd36677",
      },
    },
    component: () => import("@/pages/ClientDigitalInvoice.vue"),
  },

  // Restricted
  {
    path: "/estimate-builder/:template?:id?",
    name: "Estimate Builder",
    meta: {requiresAuth: true},
    component: () => import("@/pages/EstimateBuilder.vue"),
  },
  {
    path: "/invoice-builder/:template?:id?",
    name: "Invoice Builder",
    meta: {requiresAuth: true},
    component: () => import("@/pages/InvoiceBuilder.vue"),
  },
  {
    path: "/event-details/:id",
    name: "Event Details",
    params: true,
    meta: {requiresAuth: true},
    component: () => import("@/pages/EventDetails.vue"),
  },
  {
    path: "/estimate-rate-config",
    name: "Estimate Rate Config",
    meta: {requiresAuth: true},
    component: () => import("@/pages/EstimateRateConfig.vue"),
  },

  // Default Layout
  {
    path: "/",
    component: () => import("@/layouts/DefaultLayout.vue"),
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "",
        name: "Dashboard",
        meta: {showBanner: true},
        component: () => import("@/pages/Dashboard.vue"),
      },
      {
        path: "/estimates/:sort?",
        name: "Estimates",
        params: true,
        meta: {showBanner: true},
        component: () => import("@/pages/Estimates.vue"),
      },
      {
        path: "/estimate-details/:id",
        name: "Estimate Details",
        params: true,
        component: () => import("@/pages/EstimateDetails.vue"),
      },
      {
        path: "/invoices/:sort?",
        name: "Invoices",
        params: true,
        meta: {showBanner: true},
        component: () => import("@/pages/Invoices.vue"),
      },
      {
        path: "/invoice-details/:id",
        name: "Invoice Details",
        params: true,
        component: () => import("@/pages/InvoiceDetails.vue"),
      },
      {
        path: "/contacts",
        name: "Contacts",
        meta: {showBanner: true},
        component: () => import("@/pages/Contacts.vue"),
      },
      {
        path: "/contact-details/:id",
        name: "Contact Details",
        params: true,
        component: () => import("@/pages/ContactDetails.vue"),
      },
      {
        path: "/calendar",
        name: "Calendar",
        meta: {showBanner: true},
        component: () => import("@/pages/Calendar.vue"),
      },

      {
        path: "/leads",
        name: "Leads",
        meta: {showBanner: true},
        component: () => import("@/pages/Leads.vue"),
      },
      {
        path: "/lead-details/:id",
        name: "Lead Details",
        params: true,
        component: () => import("@/pages/LeadDetails.vue"),
      },
      {
        path: "/job-market",
        name: "Job Market",
        component: () => import("@/pages/ProConnect.vue"),
      },
      {
        path: "/company-details/:id",
        name: "Company Details",
        params: true,
        component: () => import("@/pages/CompanyDetails.vue"),
      },
      {
        path: "/support",
        name: "Support",
        meta: {
          requiresAuth: true,
          showBanner: true,
        },
        component: () => import("@/pages/Support.vue"),
      },
    ],
  },

  {
    path: "/settings",
    component: () => import("@/layouts/SettingsLayout.vue"),
    meta: {requiresAuth: true},
    children: [
      {
        path: "company-profile",
        name: "Company Profile",
        params: true,
        component: () => import("@/pages/SettingsProfileCompany.vue"),
      },
      {
        path: "account",
        name: "Account",
        params: true,
        component: () => import("@/pages/SettingsAccount.vue"),
      },
      {
        path: "estimate",
        name: "Estimate Templates",
        params: true,
        component: () => import("@/pages/SettingsEstimate.vue"),
      },
      {
        path: "invoice",
        name: "Invoice Settings",
        params: true,
        component: () => import("@/pages/SettingsInvoice.vue"),
      },
      {
        path: "line-items",
        name: "Line Items",
        params: true,
        component: () => import("@/pages/SettingsLineItems.vue"),
      },
      {
        path: "taxes",
        name: "Tax Rates",
        params: true,
        component: () => import("@/pages/SettingsTax.vue"),
      },
      {
        path: "documents",
        name: "Documents & Contracts",
        params: true,
        component: () => import("@/pages/SettingsDocuments.vue"),
      },
      {
        path: "attachments",
        name: "Documents & Scope",
        params: true,
        component: () => import("@/pages/SettingsAttachments.vue"),
      },
      {
        path: "email-templates",
        name: "Email Templates",
        params: true,
        component: () => import("@/pages/SettingsEmailTemplates.vue"),
      },
      {
        path: "social-links",
        name: "Social Links",
        params: true,
        component: () => import("@/pages/SettingsSocialLinks.vue"),
      },
      {
        path: "communication",
        name: "Email & Social",
        params: true,
        component: () => import("@/pages/SettingsCommunication.vue"),
      },
      {
        path: "job-market",
        name: "Job Market Settings",
        params: true,
        component: () => import("@/pages/SettingsProConnect.vue"),
      },
      {
        path: "subscription",
        name: "Subscription",
        params: true,
        component: () => import("@/pages/SettingsSubscriptions.vue"),
      },
      {
        path: "general",
        name: "General",
        params: true,
        component: () => import("@/pages/SettingsGeneral.vue"),
      },
      {
        path: "support",
        name: "Settings Support",
        meta: {
          requiresAuth: true,
          showBanner: true,
        },
        component: () => import("@/pages/Support.vue"),
      },
    ],
  },
  {
    path: "/website-builder",
    component: () => import("@/layouts/WebsiteBuilderLayout.vue"),
    meta: {requiresAuth: true},
    children: [
      {
        path: "",
        name: "Website Editor",
        component: () => import("@/pages/WebsiteEditor.vue"),
      },
      {
        path: "company-profile",
        name: "Company profile",
        component: () => import("@/pages/SettingsProfileCompany.vue"),
      },
      {
        path: "image-gallery",
        name: "Image Gallery",
        component: () => import("@/pages/SettingsProfileCompany.vue"),
      },
      {
        path: "global-settings",
        name: "Global Settings",
        component: () => import("@/pages/SettingsWebsiteGlobal.vue"),
      },
      {
        path: "subscription",
        name: "Upgrade to Premium",
        component: () => import("@/pages/SettingsSubscriptions.vue"),
      },
    ],
  },
  {
    path: "/deleted-account",
    component: () => import("@/pages/DeletedAccount.vue"),
  },
  {
    path: "/404",
    component: () => import("@/pages/404.vue"),
  },
  // Unrestricted - Page 404
  {
    path: "/:catchAll(.*)*",
    component: () => import("@/pages/404.vue"),
  },
]

export default routes
