<script setup>
  import {computed, defineAsyncComponent, onMounted, watch} from "vue"
  import {useDark} from "@vueuse/core"
  import {useSeoMeta} from "@unhead/vue"
  import {useRoute, useRouter} from "vue-router"
  import {usePlatform} from "@/composables/usePlatform.js"
  import {useAuthStore} from "@/stores/useAuthStore"
  import {useUserStore} from "@/stores/useUserStore.js"
  import {useGlobalStore} from "@/stores/useGlobalStore.js"
  import LoadSpinner from "@/components/LoadSpinner.vue"

  const GlobalNotify = defineAsyncComponent(() => import("@/components/GlobalNotify.vue"))
  const GlobalDialog = defineAsyncComponent(() => import("@/components/GlobalDialog.vue"))
  const GlobalNotifyLoading = defineAsyncComponent(
    () => import("@/components/GlobalNotifyLoading.vue")
  )

  const isDark = useDark()
  const route = useRoute()
  const router = useRouter()

  const global = useGlobalStore()
  const authStore = useAuthStore()
  const userStore = useUserStore()

  const user = computed(() => userStore.user)
  const company = computed(() => userStore.company)

  onMounted(() => {
    authStore.handleAuthStateChange()
  })

  watch(
    () => isDark.value,
    () => {
      if (usePlatform.isCapacitor) {
        authStore.setMobileAccessories()
      }
    }
  )

  watch([() => route.path, () => company.value?.id], ([path, companyId]) => {
    if (user.value?.uid && companyId && ["/sign-in", "/sign-up"].includes(path)) {
      global.isLoadingAccount = false
      router.push("/")
    }
  })

  const metaHead = computed(() => route.meta?.head)

  useSeoMeta({
    title: () => metaHead.value?.title || "PaintBox: The #1 App for Painters",
    ogTitle: () => metaHead.value?.title || "PaintBox: The #1 App for Painters",
    twitterTitle: () => metaHead.value?.title || "PaintBox: The #1 App for Painters",
    description: () => metaHead.value?.description || "Sign up for a free account today",
    ogDescription: () => metaHead.value?.description || "Sign up for a free account today",
    twitterDescription: () => metaHead.value?.description || "Sign up for a free account today",
    ogType: "website",
    ogUrl: () => metaHead.value?.url || "https://get.paintbox.app",
    ogImage: () => metaHead.value?.image || "paintbox_og_image.png",
    twitterImage: () => metaHead.value?.image || "paintbox_og_twitter.png",
    twitterCard: "summary_large_image",
  })
</script>

<template>
  <div class="min-h-screen">
    <!-- Global components -->
    <global-notify />
    <global-dialog />
    <global-notify-loading />

    <!--  <div
      v-if="global.isLoadingAccount"
      class="flex h-vh items-center">
      <load-spinner>
        <template #content> Loading... </template>
      </load-spinner>
    </div> -->

    <!-- Main Content View -->
    <router-view v-slot="{Component}">
      <transition name="fade">
        <component :is="Component" />
      </transition>
    </router-view>
  </div>
</template>
