import {computed, ref} from "vue"
import {Network} from "@capacitor/network"
import {Geolocation} from "@capacitor/geolocation"
import {fetchAddress, firebaseDb} from "@/apis"
import {acceptHMRUpdate, defineStore} from "pinia"
import {usePlatform} from "@/composables/usePlatform.js"
import {disableNetwork, enableNetwork} from "firebase/firestore"

export const useDeviceStore = defineStore("device", () => {
  // State
  const GPS = ref({
    latitude: null,
    longitude: null,
  })
  const address = ref({})
  const isOnline = ref(true)
  const isFirestoreNetworkEnabled = ref(false)
  const listeners = ref([])

  // Getters
  const online = computed(() => isOnline.value)
  const coords = computed(() => GPS.value)

  // Actions
  function setGPSData(latitude, longitude) {
    // Create a new object with the updated latitude and longitude
    // Update GPS.value with the new object
    GPS.value = {
      latitude: latitude,
      longitude: longitude,
    }

    return GPS.value
  }

  /*async function enableFirestoreNetwork() {
    await checkAndEnableFirestoreNetwork()

    Network.addListener("networkStatusChange", async (status) => {
      if (status.connected) {
        await enableNetworkConnectivity()
      } else {
        await disableNetworkConnectivity()
      }
    })

    listeners.value.push(() => Network.removeAllListeners())
  }

  async function checkAndEnableFirestoreNetwork() {
    const status = await Network.getStatus()
    console.log("Status", status)
    if (status.connected) {
      await enableNetworkConnectivity()
    } else {
      await disableNetworkConnectivity()
    }
  }

  async function enableNetworkConnectivity() {
    try {
      await enableNetwork(firebaseDb)
      isOnline.value = true
      console.log("network enabled 👍🏻")
    } catch (error) {
      console.error("Error enabling Firestore network:", error)
      // Handle the error appropriately
    }
  }

  async function disableNetworkConnectivity() {
    try {
      await disableNetwork(firebaseDb)
      isOnline.value = false
      console.log("network disabled 🚫")
    } catch (error) {
      console.error("Error disabling Firestore network:", error)
      // Handle the error appropriately
    }
  }*/

  async function enableFirestoreNetwork() {
    await checkAndEnableFirestoreNetwork()

    Network.addListener("networkStatusChange", async (status) => {
      if (status.connected) {
        await enableNetworkConnectivity()
      } else {
        await disableNetworkConnectivity()
      }
    })

    listeners.value.push(() => Network.removeAllListeners())
  }

  async function checkAndEnableFirestoreNetwork() {
    const status = await Network.getStatus()
    console.log("Status", status)
    if (status.connected) {
      await enableNetworkConnectivity()
    } else {
      await disableNetworkConnectivity()
    }
  }

  async function enableNetworkConnectivity() {
    if (!isFirestoreNetworkEnabled.value) {
      // Check if network is already enabled
      try {
        await enableNetwork(firebaseDb)
        isFirestoreNetworkEnabled.value = true // Update flag
        isOnline.value = true
        console.log("network enabled 👍🏻")
      } catch (error) {
        console.error("Error enabling Firestore network:", error)
      }
    }
  }

  async function disableNetworkConnectivity() {
    if (isFirestoreNetworkEnabled.value) {
      // Check if network is already disabled
      try {
        await disableNetwork(firebaseDb)
        isFirestoreNetworkEnabled.value = false // Update flag
        isOnline.value = false
        console.log("network disabled 🚫")
      } catch (error) {
        console.error("Error disabling Firestore network:", error)
      }
    }
  }

  async function fetchAddressFromGPS() {
    try {
      const coords = await fetchCurrentPosition()
      address.value = await useFetchAddress(coords.latitude, coords.longitude)
      return address.value
    } catch (error) {
      throw new Error("Unable to get address from GPS")
    }
  }

  async function fetchCurrentPosition() {
    try {
      if (typeof GPS.value.latitude === "number" && typeof GPS.value.longitude === "number") {
        return GPS.value
      }

      if (usePlatform.isCapacitor) {
        return await fetchPositionCapacitor()
      } else {
        return await fetchPositionBrowser()
      }
    } catch (e) {
      console.error("Error fetchCurrentPosition store: " + e.message)
    }
  }

  async function fetchPositionCapacitor() {
    let permission = await Geolocation.checkPermissions()

    if (permission.location === "denied") {
      // Inform the user about the denial and provide alternative actions.
      throw new Error("Location permission denied.")
    }

    const {coords} = await Geolocation.getCurrentPosition({enableHighAccuracy: true})
    GPS.value = coords
    return coords
  }

  async function fetchPositionBrowser() {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(
        ({coords}) => {
          GPS.value = coords
          resolve(coords)
        },
        (error) => {
          reject(new Error("Unable to get GPS location: " + error.message))
        }
      )
    })
  }

  async function useFetchAddress(latitude, longitude, address) {
    try {
      return await fetchAddress(latitude, longitude, address)
    } catch (error) {
      throw new Error("Unable to get address from GPS coordinates")
    }
  }

  function $reset() {
    // State
    GPS.value = {
      latitude: null,
      longitude: null,
    }
    address.value = {}
  }

  return {
    $reset,
    listeners,
    GPS,
    coords,
    address,
    isOnline,
    online,
    setGPSData,
    enableFirestoreNetwork,
    enableNetworkConnectivity,
    disableNetworkConnectivity,
    checkAndEnableFirestoreNetwork,
    fetchAddressFromGPS,
    fetchCurrentPosition,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useDeviceStore, import.meta.hot))
}
