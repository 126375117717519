import {cloneDeep} from "lodash"
import {useUid} from "@/utils/useUid.js"

// Exported
async function updateEstimateV1ToV3(item) {
  delete item?.altContact
  delete item?.materialProfits
  delete item?.subcontractor
  delete item?.terms
  delete item?.addTotalLabor
  return {
    attachments: item.attachments ? item.attachments : [],
    companyId: item.company.id,
    company: {id: item.company.id},
    createdAt: item.createdAt,
    contact: cloneDeep(item.contact),
    deposit: item.deposit ? item.deposit : null,
    depositType: item.depositType ? item.depositType : "$",
    details: details(item), // exterior
    discount: item.discount ? item.discount : null,
    discountType: item.discountType ? item.discountType : "$",
    estimateRef: item.estimateRef ? item.estimateRef : "",
    id: item.id,
    labor: cloneDeep([...updateLabor(item), ...additional(item).labor]),
    measurements: item.measurements ? item.measurements : [],
    notes: item.notes ? item.notes : "",
    products: cloneDeep([...updateProducts(item), ...additional(item).products]),
    production: item?.exterior
      ? item.company.production.ext
      : item.interior
        ? item.company.production.int
        : null,
    rooms: rooms(item), // interior
    status: item.status ? updateStatus(item.status) : {status: "draft"},
    total: parseFloat(item.subTotal - item.discount),
    totalSqft: item.totalSqft ? item.totalSqft : 0, // exterior
    type: item?.exterior ? "exterior paint" : item.interior ? "interior paint" : "general",
    user: {uid: item.user.id ? item.user.id : null},
    subContractorOffered: item.subContractorOffered ? item.subContractorOffered : [],
    signature: item?.signature || null,
    pictures: item.pictures || [],
    version: "v3",
  }
}

// local
function updateStatus(item) {
  let pending = item.pending && item?.emailed
  let signed = item.signed
  let lowerStatus = item.status.toLowerCase()
  if (["pending", "opened", "sent"].includes(lowerStatus) || pending) {
    item.status = "pending"
  } else if (["active", "accepted"].includes(lowerStatus) || signed) {
    item.status = "accepted"
  } else if (lowerStatus === "complete") {
    item.status = "complete"
  } else if (["declined", "canceled"].includes(lowerStatus)) {
    item.status = "canceled"
  } else {
    item.status = "draft"
  }

  return item
}

// Local Details - sqft based examples
function details(item) {
  let array = []
  if (item?.exterior) {
    let userList = [
      {
        desc: "Condition",
        cost: parseFloat(item?.company?.production?.ext?.condition / 100),
        qty: item.condition,
        key: "condition",
      },
      {
        desc: "Difficulty",
        cost: parseFloat(item?.company?.production?.ext?.difficulty / 100),
        qty: item.difficulty,
        key: "difficulty",
      },
      {
        desc: "Colors",
        cost: parseFloat(item?.company?.production?.ext?.colorIncrease / 100),
        qty: item.colors,
        key: "colors",
      },
    ]
    userList.forEach((item) => {
      if (item.qty) {
        array.push(item)
      }
    })
  }
  return array
}

// local
function rooms(item) {
  let array = []
  if (item?.interior) {
    Object.keys(item.rooms).forEach((key) => {
      let room = item.rooms[key]
      let data = {
        key: useUid(),
        desc: room.desc,
        length: room.length,
        width: room.width,
        height: room.height,
        ceilingSqft: room.ceilingSqft,
        trimFt: room.trimFt,
        doors: room.doors,
        windows: room.windows ? room.windows : 0,
        features: room.features,
        notes: room.notes,
        isWalls: room.addWalls,
        isTrim: room.addTrim,
        isCeiling: room.addCeiling,
        isCrown: room.addCrown,
        qty: room.qty ? room.qty : 1,
        cost: room.roomTotal,
      }
      array.push(data)
    })
  }
  return array
}

function updateProducts(item) {
  let array = []
  if (item?.exterior) {
    let extProducts = [
      {
        key: "coating",
        desc: item.coating.product,
        qty: item.qtyCoating,
        cost: item.coating.cost,
        retail: item.coating.retail,
      },
      {
        key: "primer",
        desc: item.primer.product,
        qty: item.qtyPrimer,
        cost: item.primer.cost,
        retail: item.primer.retail,
      },
      {
        key: "caulking",
        desc: item.caulking.product,
        qty: item.qtyCaulking,
        cost: item.caulking.cost,
        retail: item.caulking.retail,
      },
      {
        key: "masking",
        desc: item.masking.product,
        qty: item.qtyMasking,
        cost: item.masking.cost,
        retail: item.masking.retail,
      },
      {
        key: "tape",
        desc: item.tape.product,
        qty: item.qtyTape,
        cost: item.tape.cost,
        retail: item.tape.retail,
      },
    ]
    extProducts.forEach((item) => {
      if (item.qty) {
        let newItem = {
          key: useUid(),
          product: item.desc,
          cost: item.cost,
          retail: item.retail,
          qty: item.qty,
        }
        array.push(newItem)
      }
    })
  }
  if (item.interior) {
    let extProducts = [
      {
        key: "coating",
        desc: item.coating.product,
        qty: item.qtyCoating,
        cost: item.coating.cost,
        retail: item.coating.retail,
      },
      {
        key: "ceilingCoating",
        desc: item.ceilingCoating.product,
        qty: item.qtyCeilingCoating,
        cost: item.ceilingCoating.cost,
        retail: item.ceilingCoating.retail,
      },
      {
        key: "trimCoating",
        desc: item.trimCoating.product,
        qty: item.qtyTrimCoating,
        cost: item.trimCoating.cost,
        retail: item.trimCoating.retail,
      },
      {
        key: "primer",
        desc: item.primer.product,
        qty: item.qtyPrimer,
        cost: item.primer.cost,
        retail: item.primer.retail,
      },
      {
        key: "caulking",
        desc: item.caulking.product,
        qty: item.qtyCaulking,
        cost: item.caulking.cost,
        retail: item.caulking.retail,
      },
      {
        key: "masking",
        desc: item.masking.product,
        qty: item.qtyMasking,
        cost: item.masking.cost,
        retail: item.masking.retail,
      },
      {
        key: "tape",
        desc: item.tape.product,
        qty: item.qtyTape,
        cost: item.tape.cost,
        retail: item.tape.retail,
      },
    ]
    extProducts.forEach((item) => {
      if (item.qty) {
        let newItem = {
          key: useUid(),
          product: item.desc,
          cost: item.cost,
          retail: item.retail,
          qty: item.qty,
        }
        array.push(newItem)
      }
    })
  }
  return array
}

function updateLabor(item) {
  let array = []
  if (item?.exterior) {
    // Handle static labor fields for old estimates
    let list = [
      {
        key: "door",
        desc: "Door",
        qty: item.totalDoors,
        cost: item?.company?.production?.ext?.door,
      },
      {
        key: "garageDoor",
        desc: "Garage Door",
        qty: item.totalDoorGarage,
        cost: item?.company?.production?.ext?.doorGarage,
      },
      {
        key: "window",
        desc: "Window",
        qty: item.totalWindows,
        cost: item?.company?.production?.ext?.window,
      },
      {
        key: "sash",
        desc: "Window Sash",
        qty: item.totalWindowSashes,
        cost: item?.company?.production?.ext?.windowSash,
      },
    ]

    list.forEach((item) => {
      if (item.qty) {
        let newItem = {
          cost: item.cost,
          desc: item.desc,
          qty: item.qty,
          key: item.key,
        }
        array.push(newItem)
      }
    })
  }
  return array
}

function additional(item) {
  let labor = []
  let products = []
  // handle additional fields for old estimates
  if (item.additional.length) {
    item.additional.forEach((item) => {
      if (item.labor) {
        let newItem = {
          cost: item.cost,
          desc: item.desc,
          qty: item.qty,
          key: useUid(),
        }
        labor.push(newItem)
      }
      if (!item.labor) {
        let newItem = {
          retail: item.cost,
          cost: item.cost,
          product: item.desc,
          qty: item.qty,
          key: useUid(),
        }

        products.push(newItem)
      }
    })
  }
  return {
    labor: labor,
    products: products,
  }
}

export {updateEstimateV1ToV3}
