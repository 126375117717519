import {acceptHMRUpdate, defineStore} from "pinia"
import {deleteFirebaseData, firebaseAuth, firebaseDb, updateFirebaseData} from "@/apis"
import {useUserStore} from "@/stores/useUserStore.js"
import {FirebaseMessaging} from "@capacitor-firebase/messaging"
import {Badge} from "@capawesome/capacitor-badge"
import {computed, ref} from "vue"
import {collection, onSnapshot, query, where} from "firebase/firestore"

export const useNotificationStore = defineStore("notification", () => {
  // State
  const notifications = ref(new Map())
  const listeners = ref([])
  const bannerAlert = ref({
    upgrade: true,
    tradebox: true,
  })

  // Getters
  const notificationsSorted = computed(() => {
    const array = Array.from(notifications.value.values())
    return array.sort((a, b) => new Date(b?.createdAt) - new Date(a?.createdAt))
  })

  // Actions

  function fetchNotifications(uid) {
    if (!uid) throw new Error("User ID is required")
    if (!firebaseAuth.currentUser) throw new Error("Unauthorized User")

    const q = query(collection(firebaseDb, "notifications"), where("companyId", "==", uid))
    const unsubscribe = onSnapshot(
      q,
      (querySnapshot) => {
        querySnapshot.forEach((doc) => {
          const data = doc.data()
          notifications.value.set(data.id, data)
        })
      },
      (error) => {
        console.error("Error fetching notifications:", error)
        // Handle the error appropriately, such as updating the UI or state
      }
    )

    listeners.value.push(unsubscribe)
  }

  async function deleteNotification(value) {
    notifications.value.delete(value.id)
    const count = notificationsSorted.value?.length
    // delete from firebase
    await deleteFirebaseData("notifications", value.id, "", "", "")
    // set badge count
    const {isSupported} = await Badge.isSupported()
    if (isSupported) await Badge.set({count})
  }

  async function registerNotifications() {
    const userStore = useUserStore()
    const {receive} = await FirebaseMessaging.checkPermissions()

    let status
    switch (receive) {
      case "denied":
        break
      case "granted":
        status = "granted"
        break
      default:
        status = await FirebaseMessaging.requestPermissions()
        break
    }
    if (status === "granted") {
      const result = await FirebaseMessaging.getToken()
      if (result.token && result.token !== userStore.user?.device) {
        await updateFirebaseData("users", userStore.user?.uid, "device", result.token)
      }
      return result.token
    }
  }

  /* async function getDeliveredNotifications () {
        const result = await FirebaseMessaging.getDeliveredNotifications()
        return result.notifications
      }*/

  /* async function removeNotifications () {
        await FirebaseMessaging.removeAllDeliveredNotifications()
      }*/

  // unsubscribe from listeners on auth state change
  async function unsubscribe() {
    listeners.value.forEach((fn) => fn())
    await FirebaseMessaging.removeAllListeners()
  }

  function $reset() {
    notifications.value = new Map()
    listeners.value = []
    bannerAlert.value = {
      upgrade: true,
      tradebox: true,
    }
  }

  return {
    bannerAlert,
    notifications,
    notificationsSorted,
    fetchNotifications,
    deleteNotification,
    registerNotifications,
    unsubscribe,
    listeners,
    $reset,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useNotificationStore, import.meta.hot))
}
