import {acceptHMRUpdate, defineStore} from "pinia"
import "cordova-plugin-purchase"
import {Device} from "@capacitor/device"
import {useUserStore} from "@/stores/useUserStore.js"
import {ref} from "vue"

export const useIAPStore = defineStore("IAP", () => {
  // Store
  const products = ref([])
  const theStore = ref(null)
  const purchaseToken = ref(null)

  // Actions
  async function initProducts() {
    const {store, Platform, ProductType} = CdvPurchase
    const deviceInfo = await Device.getInfo()
    const userDevice = deviceInfo.platform
    theStore.value = store
    const group = "monthly_subscription"
    const platform =
      userDevice === "ios"
        ? Platform.APPLE_APPSTORE
        : userDevice === "android"
          ? Platform.GOOGLE_PLAY
          : null
    const productIdsApple = [
      "pro_monthly",
      "pro_web_monthly",
      "premium_monthly",
      "paintbox_monthly_pro_30", // paintbox_monthly_pro_30 depreciated
    ]

    const productIdsGoogle = [
      "pro",
      "pro_web",
      "premium",
      "paintbox_monthly_pro_30", // paintbox_monthly_pro_30 depreciated
    ]

    const productIds = userDevice === "ios" ? productIdsApple : productIdsGoogle

    const products_to_register = productIds.map((id) => ({
      id,
      type: ProductType.PAID_SUBSCRIPTION,
      platform,
      group,
    }))

    await store.register(products_to_register)
    await setupListeners(store)

    const iaptic = new CdvPurchase.Iaptic({
      appName: "app.paintbox",
      apiKey: "e0944d2d-e960-4d4f-bc03-dd59a7996840",
    })

    store.validator = iaptic.validator
    await store.initialize()
    await setUser()

    store.ready(() => {
      // products.value = store.products
      // console.log('store ready')
    })
  }

  function setUser(uid) {
    const {store} = CdvPurchase
    const userStore = useUserStore()
    const {user} = userStore
    uid = uid || user.uid
    store.applicationUsername = uid
  }

  function setupListeners(store) {
    store
      .when()
      .approved((transaction) => {
        // console.log('transaction approved', JSON.stringify(transaction))
        transaction.verify()
      })
      .verified(async (receipt) => {
        // console.log('receipt verified:', JSON.stringify(receipt))
        receipt.finish()

        if (receipt.sourceReceipt.transactions.length > 0) {
          const lastReceipt = receipt.sourceReceipt.transactions.pop()
          // console.log('lastReceipt:', JSON.stringify(lastReceipt))

          await assignProducts(lastReceipt.products)

          if (receipt.latestReceipt?.nativePurchase?.autoRenewing) {
            purchaseToken.value = receipt.sourceReceipt?.purchaseToken
          }
        }
      })
      .unverified(() => {
        // console.log('receipt unverified:', JSON.stringify(receipt))
      })
      .productUpdated((product) => {
        const index = products.value.findIndex((p) => p?.id === product.id)
        // console.log('product.type:', JSON.stringify(product.type))

        if (index !== -1) {
          products.value[index] = product
        } else {
          products.value.push(product)
        }
      })
  }

  async function assignProducts(array) {
    const {company} = useUserStore()
    const currentIdx = ["pro", "pro_web", "premium"].indexOf(company.plan.type)
    const newID = array[0].id
    const newIdx = ["pro", "pro_web", "premium"].indexOf(newID)

    if (newIdx > currentIdx && !["stripe", "admin"].includes(user?.provider)) {
      company.plan.type = matchPlan(newID)
    }
  }

  function matchPlan(str) {
    return str
      ? str
          .toLowerCase()
          .replace("paintbox_monthly_pro_30", "pro_web")
          .replace("_monthly", "")
          .replace(/-/g, "_")
      : ""
  }

  async function inAppPurchase(planId, company_uid) {
    try {
      const {store, GooglePlay} = CdvPurchase
      const googlePlay = purchaseToken.value
        ? {
            prorationMode: GooglePlay.ProrationMode.IMMEDIATE_AND_CHARGE_FULL_PRICE,
            oldPurchaseToken: purchaseToken.value,
          }
        : {prorationMode: GooglePlay.ProrationMode.IMMEDIATE_AND_CHARGE_FULL_PRICE}

      await setUser(company_uid)
      await store.get(planId).getOffer().order({
        applicationUsername: company_uid,
        googlePlay,
      })
    } catch (e) {
      console.log("ERROR. Failed to find product order. " + e.code + ": " + e.message)
    }
  }

  async function manageSubscriptions() {
    const {store} = CdvPurchase
    store.manageSubscriptions()
  }

  async function restorePurchase() {
    const {store} = CdvPurchase
    await store.restorePurchases()
  }

  function $reset() {
    products.value = []
    theStore.value = null
    purchaseToken.value = null
  }

  return {
    products,
    initProducts,
    setUser,
    setupListeners,
    assignProducts,
    matchPlan,
    inAppPurchase,
    manageSubscriptions,
    restorePurchase,
    $reset,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useIAPStore, import.meta.hot))
}
