import {cloneDeep} from "lodash"
import {computed, ref} from "vue"
import {useRouter} from "vue-router"
import {fbDoc} from "@/apis/firebase_config.js"
import {estimate as estimateRef} from "@/utils"
import {acceptHMRUpdate, defineStore} from "pinia"
import {useUserStore} from "@/stores/useUserStore.js"
import {useGlobalStore} from "@/stores/useGlobalStore.js"
import {firebaseDb, getFirebaseData} from "@/apis/index.js"
import {updateEstimateV1ToV3} from "@/utils/updateEstimateV1ToV3.js"
import {updateEstimateV3ToV4} from "@/utils/updateEstimateV3ToV4.js"
import {collection, getDocs, onSnapshot, query, where, writeBatch} from "firebase/firestore"

export const useEstimateStore = defineStore("estimate", () => {
  // Utils
  const router = useRouter()

  // Stores
  const userStore = useUserStore()
  const globalStore = useGlobalStore()

  // State
  const estimates = ref(new Map())
  const estimate = ref(cloneDeep(estimateRef))
  const listeners = ref([])
  const fetchedYears = ref(new Set())
  const fetchedContacts = ref(new Set())
  const activeYear = ref(null)
  const skipEstimateConfig = ref(false)

  // Getters
  const estimatesByStatus = computed(() => {
    let estimateArray = Array.from(estimates.value.values())
    estimateArray = estimateArray.sort((a, b) => {
      const dateA = new Date(a.createdAt).getTime()
      const dateB = new Date(b.createdAt).getTime()
      return dateB - dateA
    })
    // Filter the estimates created within the current month
    const currentMonth = new Date().getMonth()
    const currentYear = new Date().getFullYear()
    const currentMonthEstimates = estimateArray.filter((estimate) => {
      const estimateDate = new Date(estimate.createdAt)
      const estimateMonth = estimateDate.getMonth()
      const estimateYear = estimateDate.getFullYear()
      return estimateMonth === currentMonth && estimateYear === currentYear
    })

    return {
      all: estimateArray,
      currentMonth: currentMonthEstimates,
      draft: estimateArray.filter((estimate) => estimate.status === "draft"),
      pending: estimateArray.filter((estimate) => estimate.status === "pending"),
      accepted: estimateArray.filter((estimate) => estimate.status === "accepted"),
      complete: estimateArray.filter((estimate) => estimate.status === "complete"),
      canceled: estimateArray.filter((estimate) => estimate.status === "canceled"),
    }
  })

  const hasEstimateAccess = computed(() => {
    const userStore = useUserStore()
    return userStore.isPremium || estimatesByStatus.value.currentMonth.length < 3
  })

  const dashboardStats = computed(() => {
    const draft = estimatesByStatus.value.draft
    const pending = estimatesByStatus.value.pending
    const accepted = estimatesByStatus.value.accepted

    const totalDrafts = draft.reduce((total, est) => total + est.total, 0)
    const totalPending = pending.reduce((total, est) => total + est.total, 0)
    const totalAccepted = accepted.reduce((total, est) => total + est.total, 0)

    return {
      draft: totalDrafts,
      pending: totalPending,
      accepted: totalAccepted,
    }
  })

  const estimateSelectList = computed(() => {
    const userStore = useUserStore()
    return userStore.sortedEstimateTemplates.map((item) => {
      return {
        value: item,
        label: item.name,
        action: () =>
          router.push({
            name: "Estimate Builder",
            params: {template: item?.key},
          }),
        secondary: item.secondary,
      }
    })
  })

  // Actions
  function fetchEstimatesByYear(uid, year) {
    if (!uid) throw new Error("User ID is required")
    if (!year) {
      year = new Date().getFullYear()
    }

    if (fetchedYears.value.has(year)) {
      return Promise.resolve({skipped: true})
    }
    const startOfYear = new Date(year, 0, 1).getTime()
    const endOfYear = new Date(year + 1, 0, 1).getTime()

    let collectionRef = collection(firebaseDb, "estimates")
    const queryRef = query(
      collectionRef,
      where("company.id", "==", uid),
      where("createdAt", ">=", startOfYear),
      where("createdAt", "<", endOfYear)
    )

    const unsubscribe = onSnapshot(queryRef, (querySnapshot) => {
      let estimateData = querySnapshot.docs.map((doc) => doc.data())
      let batch = writeBatch(firebaseDb)
      let updateCount = 0
      let updatePromises = []

      estimateData.forEach((estimate) => {
        const updatePromise = updateEstimatesToCurrentVersion(estimate, batch)
          .then((updateResult) => {
            if (updateResult.updated) {
              updateCount++
              estimate = updateResult.estimate
            }
            estimates.value.set(estimate.id, estimate)

            if (updateCount % 450 === 0 && updateCount !== 0) {
              batch.commit()
              batch = writeBatch(firebaseDb)
            }
          })
          .catch((updateError) => {
            console.error(`Error updating estimate ${estimate.id}:`, updateError)
          })
        updatePromises.push(updatePromise)
      })

      return Promise.all(updatePromises).then(() => {
        if (updateCount > 0 && updateCount % 450 !== 0) {
          return batch.commit()
        }
      })
    })

    fetchedYears.value.add(year)
    listeners.value.push(unsubscribe)
    return {complete: true}
  }

  async function updateEstimatesToCurrentVersion(estimate, batch) {
    if (!estimate || !batch) {
      throw new Error("Invalid parameters passed to updateEstimatesToCurrentVersion")
    }

    let updated = false
    if (!estimate.version) {
      estimate = await updateEstimateV1ToV3(estimate)
    }

    if (estimate.version === "v3") {
      estimate = await updateEstimateV3ToV4(estimate)
      updated = true
    }

    if (updated) {
      const docRef = fbDoc(firebaseDb, "estimates", `${estimate.id}`)
      batch.update(docRef, estimate) // Add update to batch
    }

    return {
      updated,
      estimate,
    } // Return both update status and the estimate
  }

  async function fetchAndSetNewEstimate(docId) {
    const data = await getFirebaseData("estimates", `${docId}`, "", "")
    const estimate = data.data
    estimates.value.set(estimate.id, estimate)
  }

  function handleOpenEstimateOptions() {
    if (hasEstimateAccess.value) {
      return true
    } else {
      showUpgradeDialog()
    }
  }

  function showUpgradeDialog() {
    globalStore.openDialog(
      "warning",
      "Upgrade to Premium",
      "You have used your estimates for the month. Upgrade to access unlimited estimates.",
      {
        name: "Upgrade Now",
        action: () =>
          router.push({
            name: "Settings",
            params: {tab: "Subscription"},
          }),
      }
    )
  }

  /**
   * @param contact
   * @return {Array} A specific client estimates
   */
  async function getEstimatesForContact(contact) {
    const fetchedIds = Array.from(estimates.value.keys()).slice(0, 10)
    try {
      await fetchNewContactEstimates(contact, fetchedIds)
    } catch (e) {
      console.error("Error fetching estimates: ", e)
      throw e
    }

    let array = Array.from(estimates.value.values())
    array = array.filter((estimate) => {
      // Check if both email fields are not null or empty
      const emailConditionsMet =
        estimate.contact.email &&
        contact.email &&
        estimate.contact.email.trim() !== "" &&
        contact.email.trim() !== ""

      // Proceed with email comparison only if both emails have valid values
      if (emailConditionsMet) {
        return estimate.contact.email === contact.email
      } else {
        // If email comparison is not applicable, compare contact IDs
        return estimate.contact.contactId === contact.contactId
      }
    })

    return array.sort((a, b) => {
      const dateA = new Date(a.createdAt).getTime()
      const dateB = new Date(b.createdAt).getTime()
      return dateB - dateA // Sort in descending order by createdAt date
    })
  }

  async function fetchNewContactEstimates(contact, excludeIds) {
    if (fetchedContacts.value.has(contact.contactId)) {
      return
    }

    const company = userStore.company
    const estimatesCollection = collection(firebaseDb, "estimates")
    const q = query(
      estimatesCollection,
      where("company.id", "==", company.id),
      where("contact.contactId", "==", contact.contactId),
      where("id", "not-in", excludeIds)
    )

    const querySnapshot = await getDocs(q)
    querySnapshot.forEach((doc) => {
      let estimate = doc.data()
      if (!estimate.version) {
        estimate = updateEstimateV1ToV3(estimate)
      }

      if (estimate.version === "v3") {
        estimate = updateEstimateV3ToV4(estimate)
      }

      estimates.value.set(estimate.id, estimate)
    })

    fetchedContacts.value.add(contact.contactId)
  }

  function resetEstimates() {
    estimate.value = cloneDeep(estimateRef)
  }

  function $reset() {
    estimates.value = new Map()
    estimate.value = cloneDeep(estimateRef)
    listeners.value = []
    fetchedYears.value = new Set()
    fetchedContacts.value = new Set()
    activeYear.value = null
    skipEstimateConfig.value = false
    resetEstimates()
  }

  return {
    estimates,
    estimate,
    resetEstimates,
    estimateSelectList,
    hasEstimateAccess,
    getEstimatesForContact,
    handleOpenEstimateOptions,
    skipEstimateConfig,
    showUpgradeDialog,
    estimatesByStatus,
    fetchedYears,
    activeYear,
    dashboardStats,
    fetchAndSetNewEstimate,
    fetchEstimatesByYear,
    listeners,
    $reset,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useEstimateStore, import.meta.hot))
}
