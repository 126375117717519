import {cloneDeep} from "lodash"
import {useRouter} from "vue-router"
import {computed, ref, watch} from "vue"
import {Browser} from "@capacitor/browser"
import {getDoc, onSnapshot} from "firebase/firestore"
import {httpsCallable} from "firebase/functions"
import {acceptHMRUpdate, defineStore} from "pinia"
import {useGlobalStore} from "@/stores/useGlobalStore.js"
import {useInvoiceStore} from "@/stores/useInvoiceStore.js"
import {updateCompanyDataToV4} from "@/utils/updateEstimateV3ToV4.js"
import {company as companyRef, user as userRef} from "@/utils/constants.js"
import {fbDoc, firebaseAuth, firebaseDb, functions, updateFirebaseData} from "@/apis/index.js"
import {useGetYearFromTimestamp} from "@/utils/index.js"
import {useEstimateStore} from "@/stores/useEstimateStore.js"
import {useDeviceStore} from "@/stores/useDeviceStore.js"

export const useUserStore = defineStore("company", () => {
  // Utils
  const router = useRouter()

  // Pinia Stores
  const globalStore = useGlobalStore()
  const deviceStore = useDeviceStore()
  const invoiceStore = useInvoiceStore()
  const estimateStore = useEstimateStore()

  // State
  const count = ref(0)
  const user = ref(cloneDeep(userRef))
  const userClone = ref(cloneDeep(userRef))
  const company = ref(cloneDeep(companyRef))
  const companyClone = ref(cloneDeep(companyRef))
  const isLive = ref(true)
  const listeners = ref([])
  const yearsActive = ref([])

  // Getters
  const isPremium = computed(() =>
    ["pro", "pro_web", "premium"].includes(company.value?.plan?.type || "free")
  )
  const isPremiumWeb = computed(() =>
    ["pro_web", "premium"].includes(company.value?.plan?.type || "free")
  )
  const formattedPlanName = computed(() => company.value?.plan?.type.replace("_", " + "))

  const sortedEstimateTemplates = computed(() => {
    if (company.value.version === "v4") {
      const unsortedTemplates = Object.values(company.value.production).map((value) => {
        return {
          ...value,
          secondary: true,
        }
      })
      const sorted = unsortedTemplates.sort((a, b) => a.name.localeCompare(b.name))
      sorted.unshift({
        name: "General Estimate",
        type: "blank",
        key: "",
        production_rates: [],
      })
      return sorted
    } else {
      return []
    }
  })

  // Actions
  // watch company id change - update company and user version to v4
  watch(
    () => company.value.id,
    async (data) => {
      const authUID = firebaseAuth?.currentUser?.uid
      if (authUID && data && company.value.version !== "v4") {
        const newCompany = await updateCompanyDataToV4(company.value)
        const callable = httpsCallable(functions, "firebaseUpdateUserAndCompanyVersion")
        await callable({company: newCompany})
      }
    }
  )

  // watch user uid change - update last_sign_in
  watch(
    () => user.value.uid,
    async () => {
      const timeStamp = Date.now()
      const authUID = firebaseAuth?.currentUser?.uid
      if (authUID && user.value.uid) {
        await updateFirebaseData("users", `${authUID}`, "last_sign_in", timeStamp)
      }
      await setUserYearsActive()
    }
  )

  async function setUserYearsActive() {
    const start = useGetYearFromTimestamp(user.value.createdAt || Date.now()) // 2021
    const endYear = new Date().getFullYear()
    for (let year = start; year <= endYear; year++) {
      yearsActive.value.unshift(year)
    }
    estimateStore.activeYear = endYear
  }

  function fetchUserProfile(uid) {
    if (!uid) throw new Error("User ID is required")

    const docRef = fbDoc(firebaseDb, "users", uid)

    return getDoc(docRef).then((docSnap) => {
      if (docSnap.exists()) {
        user.value = docSnap.data()
        return user.value
      } else {
        return null
      }
    })
  }

  function fetchCompanyProfile(uid) {
    if (!uid) throw new Error("User ID is required")
    if (company.value.id) return Promise.resolve("Company is already available")

    const docRef = fbDoc(firebaseDb, "companies", uid)
    const unsubscribe = onSnapshot(
      docRef,
      async (doc) => {
        if (!doc.exists()) {
          return null
        }

        const data = doc.data()
        company.value = data
        const {lat, long} = data.contact.address
        deviceStore.setGPSData(lat, long)
        return data
      },
      (e) => {
        throw new Error("Error fetching company profile: " + e.message)
      }
    )

    listeners.value.push(unsubscribe) // Manage listener
  }

  async function handleCreateConnectAccount() {
    const globalStore = useGlobalStore()
    if (!isPremium.value) {
      globalStore.openDialog(
        "warning",
        "Unlock Invoicing",
        "Unlock secure invoicing and get paid faster by upgrading your plan.",
        {
          name: "View Plans",
          action: () =>
            router.push({
              name: "Subscription",
            }),
        }
      )
    } else {
      const inProgress =
        company.value.plan.stripe_connect.account_uid &&
        !company.value.plan.stripe_connect.charges_enabled
      let type = inProgress ? "warning" : "success"
      let title = inProgress ? "Stripe Account Incomplete" : "Connect Stripe Account"
      let text =
        "PaintBox uses Stripe to get you paid quickly and keep your personal and payment information secure. "
      text += inProgress
        ? "Complete your Stripe account to get paid with PaintBox"
        : "Set up a Stripe account to get paid with PaintBox."
      let button = inProgress ? "Finish Setup" : "Connect Stripe"
      globalStore.openDialog(type, title, text, {
        name: button,
        action: () => handleConnectAccount(),
      })
    }
  }

  async function handleConnectAccount() {
    try {
      globalStore.isPending = true
      if (invoiceStore.invoiceAccountComplete) {
        await Browser.open({url: "https://stripe.com"})
      } else {
        await authorizeStripeConnect()
      }
    } catch (e) {
      throw new Error(e)
    } finally {
      globalStore.isPending = false
    }
  }

  async function authorizeStripeConnect() {
    try {
      if (!company.value?.plan?.stripe_connect?.account_uid) {
        company.value.plan.stripe_connect.account_uid = await createStripeConnectAccount()
      }
      await fetchConnectAccountUrl()
    } catch (e) {
      throw new Error(e)
    }
  }

  async function createStripeConnectAccount() {
    const callable = httpsCallable(functions, "stripeCreateConnectAccount")
    const {data} = await callable({
      isLive: isLive.value,
      user: user.value,
      company: company.value,
    })
    return data.data.id
  }

  async function fetchConnectAccountUrl() {
    const callbackUrl = `https://paintbox.app/stripe-connect?stripe_account_uid=${company.value.plan.stripe_connect.account_uid}`

    try {
      const callable = httpsCallable(functions, "stripeConnectAccountUrl")
      const {data} = await callable({
        isLive: isLive.value,
        company: company.value,
        refresh_url: callbackUrl,
        return_url: callbackUrl,
      })
      const url = data.data?.url

      if (!url) {
        throw new Error("Connect account URL not found.")
      }

      try {
        await Browser.open({url})
      } catch (e) {
        // Handle or log the browser-specific error here
        throw new Error("Failed to open the URL in the browser: " + e)
      }
    } catch (e) {
      throw new Error("Error getting connect account URL: " + e)
    }
  }

  async function fetchStripeConnectAccount(stripe_account_uid) {
    if (!stripe_account_uid) throw new Error("No Stripe Account uid provided.")

    try {
      const callable = httpsCallable(functions, "stripeFetchConnectAccount")
      const {data} = await callable({
        isLive: isLive.value,
        stripe_account_uid,
      })
      return data.account
    } catch (e) {
      throw new Error(e)
    }
  }

  function cloneUser() {
    userClone.value = cloneDeep(user.value)
  }

  function cloneCompany() {
    companyClone.value = cloneDeep(company.value)
  }

  function $reset() {
    user.value = cloneDeep(userRef)
    userClone.value = cloneDeep(userRef)
    company.value = cloneDeep(companyRef)
    companyClone.value = cloneDeep(companyRef)
    listeners.value = []
    yearsActive.value = []
  }

  return {
    count,
    user,
    userClone,
    company,
    companyClone,
    isLive,
    isPremium,
    isPremiumWeb,
    yearsActive,
    sortedEstimateTemplates,
    formattedPlanName,
    fetchUserProfile,
    fetchCompanyProfile,
    authorizeStripeConnect,
    handleConnectAccount,
    handleCreateConnectAccount,
    fetchStripeConnectAccount,
    cloneUser,
    cloneCompany,
    listeners,
    $reset,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useUserStore, import.meta.hot))
}
