/** Import necessary Firebase SDKs and third-party libraries. */
import {initializeApp} from "firebase/app"
import {getAuth, indexedDBLocalPersistence, initializeAuth} from "firebase/auth"
import {
  doc as fbDoc,
  getFirestore,
  initializeFirestore,
  persistentLocalCache,
} from "firebase/firestore"
import {getStorage} from "firebase/storage"
import {getFunctions, httpsCallable} from "firebase/functions"
import {getAnalytics} from "firebase/analytics"
import {Capacitor} from "@capacitor/core"
/**
 * Geofire is used for creating geo-queries with Firestore. This is useful for querying documents based on geographical locations.
 * @see https://firebase.google.com/docs/firestore/solutions/geoqueries
 */

import * as geofire from "geofire-common"

/**
 * Firebase configuration object for live environment.
 */
const firebaseConfig = {
  apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
  authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
  databaseURL: import.meta.env.VITE_FIREBASE_DATABASE_URL,
  projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
  storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
  appId: import.meta.env.VITE_FIREBASE_APP_ID,
  measurementId: import.meta.env.VITE_FIREBASE_MEASUREMENT_ID,
}
/**
 * Initialize the Firebase app.
 */
const app = initializeApp(firebaseConfig)
/**
 *  Configures the Firebase firestore instance based on the platform.
 */
const isCapacitor = Capacitor.isNativePlatform()
const firebaseDbConfig = () => {
  if (isCapacitor) {
    return initializeFirestore(app, {localCache: persistentLocalCache()})
    // Use multi-tab IndexedDb persistence.
    /*   return initializeFirestore(app, {
      localCache: persistentLocalCache({tabManager: persistentMultipleTabManager()}),
    })*/
  } else {
    return getFirestore(app)
    /* return initializeFirestore(app, {
       synchronizeTabs: true
     });*/
  }
}

const firebaseDb = firebaseDbConfig()

/**
 *  Configures the Firebase Auth instance based on the platform.
 */
const firebaseAuthConfig = () => {
  if (isCapacitor) {
    return initializeAuth(app, {
      persistence: indexedDBLocalPersistence,
    })
  } else {
    return getAuth()
  }
}

const firebaseAuth = firebaseAuthConfig()
const analytics = getAnalytics(app)
const functions = getFunctions(app)
const storage = getStorage()

export {firebaseAuth, firebaseDb, functions, httpsCallable, storage, geofire, fbDoc, analytics}
