import {useUid} from "@/utils/useUid.js"

const production_rate = {
  name: "",
  key: "",
  value: 0,
  type: "$",
  unit: "count",
  qty: 0,
}

const template = {
  name: "",
  key: "",
  type: "",
  production_rates: [],
}

function createPaintingTemplate(name, type, productionRates) {
  return {
    name,
    key: useUid(),
    type,
    production_rates: productionRates,
  }
}

// Function to generate a object with default values
function createProductionRate(name, value, type, unit) {
  const num = Number.isInteger(value) ? value : parseFloat(value.toFixed(2))
  return {
    key: useUid(),
    name,
    value: num,
    type,
    unit,
    qty: 0,
  }
}

const productionRatesExterior = [
  createProductionRate("SqFt", 1.2, "$", "sqft"),
  createProductionRate("Color Increase", 10, "%", "sqft"),
  createProductionRate("Difficulty", 10, "%", "sqft"),
  createProductionRate("Condition", 10, "%", "sqft"),
  createProductionRate("Trim/gutters", 1.4, "$", "count"),
  createProductionRate("Door", 75, "$", "count"),
  createProductionRate("Garage Door", 100, "$", "count"),
  createProductionRate("Window Trim", 45, "$", "count"),
  createProductionRate("Shutter", 45, "$", "count"),
]

const productionRatesInterior = [
  createProductionRate("SqFt", 0.8, "$", "sqft"),
  createProductionRate("Ceiling", 1, "$", "sqft"),
  createProductionRate("Trim", 1.2, "$", "sqft"),
  createProductionRate("Condition", 10, "%", "sqft"),
  createProductionRate("Difficulty", 10, "%", "sqft"),
  createProductionRate("Door", 60, "$", "count"),
  createProductionRate("Window", 35, "$", "count"),
  createProductionRate("Feature", 35, "$", "count"),
]

const exterior_template = createPaintingTemplate(
  "Exterior Painting",
  "exterior",
  productionRatesExterior
)
const interior_template = createPaintingTemplate(
  "Interior Painting",
  "interior",
  productionRatesInterior
)

const structure_options = ["Contractor", "Subcontractor", "Independent", "Solo"]

const position_options = [
  "Subcontractor",
  "Prepper",
  "Painter",
  "Estimator",
  "Project Manager",
  "Other",
]

const rate_options = [
  "$15 - $30 / hour",
  "$30 - $50 / hour",
  "$50+ / hour",
  "Per Job Basis",
  "Other",
]

const service_options = [
  "commercial",
  "residential",
  "interior",
  "exterior",
  "repaint",
  "new construction",
  "cabinets",
  "repairs",
]

const directory = {
  established: new Date().getFullYear(),
  structure: "",
  services: [],
  pay_rates: [],
  status: "",
  positions: [],
  employment: "",
  isPublic: true,
}

const attachment = {
  key: "",
  url: "",
  title: "",
  filename: "",
  type: "application/pdf",
}

const email_template = {
  key: "",
  subject: "",
  message: "",
  title: "",
}

const address = {
  street: "",
  unit: "",
  city: "",
  state: "",
  zip: "",
  country: "",
  formatted: "",
  lat: null,
  long: null,
}

const company = {
  id: "",
  createdAt: 0,
  admin: [],
  license: "",
  logoUrl: "",
  geoHash: "",
  country: "",
  contractors: [],
  requests: {
    sent: [],
    received: [],
  },
  rating: {
    count: 0,
    total: 0,
  },
  estimateNumberRef: 100,
  invoiceNumberRef: 100,
  estimate_days_valid: 30,
  invoice_days_until_due: 7,
  contact: {
    name: "",
    phone: "",
    email: "",
    address: address,
    website: "",
    tradebox: "",
    yelp: "",
    instagram: "",
    facebook: "",
    showYelpReviews: true,
    businessHours: {
      days: "Week days",
      hours: "9am - 5pm",
    },
  },
  production: {},
  plan: {
    type: "free",
    provider: "",
    will_lapse: false,
    has_downgraded: false,
    period_end: 0,
    stripe: {
      customer_uid: "",
      subscription_uid: "",
    },
    stripe_connect: {
      account_uid: "",
      details_submitted: false,
      charges_enabled: false,
    },
  },
  directory: directory,
  currency: "usd",
  webBuilderUrl: "",
  version: "v4",
}

const user = {
  uid: "",
  name: "",
  email: "",
  createdAt: 0,
  myCompany: "",
  last_sign_in: 0,
  platform: {
    platform: "",
    capacitor: "",
  },
  version: "v4",
}

const contact = {
  id: "",
  contactId: "",
  uid: "",
  createdAt: null,
  email: "",
  name: "",
  phone: "",
  company: "",
  address: {
    formatted: "",
    streetNumber: "",
    streetName: "",
    street: "",
    state: "",
    countryShort: "",
    zip: "",
    country: "",
    long: null,
    lat: null,
    link: "",
    city: "",
  },
  stripe: {id: ""},
}

const lineItem = {
  key: "",
  type: "",
  label: "",
  description: "",
  applyTax: [],
  unit_amount_wholesale: null,
  unit_amount: null,
  qty: null,
}

function createLineItems(type, label, unit_amount_wholesale, unit_amount) {
  const key = useUid()
  return {
    [key]: {
      key,
      type,
      label,
      description: "",
      applyTax: [],
      unit_amount_wholesale,
      unit_amount,
      qty: null,
    },
  }
}

const defaultLineItems = {
  ...createLineItems("product", "Exterior Paint", 55, 75),
  ...createLineItems("product", "Interior Paint", 45, 65),
  ...createLineItems("product", "Masking Tape", 4, 6),
  ...createLineItems("product", "Masking Paper", 5, 8),
  ...createLineItems("labor", "Hourly Rate", 0, 80),
  ...createLineItems("labor", "Small Repair", 0, 150),
}

const tax = {
  key: "",
  desc: "",
  rate: null,
}

const room = {
  key: "",
  desc: "",
  notes: "",
  qty: 1,
  cost: 0,
  length: 0,
  width: 0,
  height: 0,
  production_rates: [],
  scope: {
    isWalls: true,
    isDoors: false,
    isCeiling: false,
    isTrim: false,
    isCrown: false,
  },
}

// Function to generate a room object with default values
function createRoom(desc, length, width, height) {
  return {
    key: useUid(),
    desc,
    cost: 0,
    qty: 1,
    length,
    width,
    height,
    production_rates: [],
    scope: {
      isWalls: true,
      isDoors: false,
      isCeiling: false,
      isTrim: false,
      isCrown: false,
    },
    notes: "",
  }
}

// Create an array of room objects
const rooms = [
  createRoom("Living room", 16, 20, 8),
  createRoom("Bedroom", 12, 14, 8),
  createRoom("Primary Bedroom", 14, 16, 8),
  createRoom("1/2 Bathroom", 5, 8, 8),
  createRoom("3/4 Bathroom", 6, 10, 8),
  createRoom("Full Bathroom", 10, 12, 8),
  createRoom("Office", 12, 12, 8),
  createRoom("Small Room", 12, 12, 8),
  createRoom("Medium Room", 14, 16, 8),
  createRoom("Large Room", 16, 20, 8),
  createRoom("Hallway", 16, 4, 8),
  createRoom("Stairwell", 24, 4, 8),
]

const estimate = {
  createdAt: 0,
  id: "",
  reference_number: 0,
  type: "general",
  user: {
    uid: "",
    name: "",
  },
  company: {id: ""},
  company_id: "",
  contact: contact,
  template,
  totalSqft: 0,
  labor: [],
  production: [],
  products: [],
  rooms: [],
  measurements: [],
  attachments: [],
  scope: [],
  photos: {},
  total: 0,
  depositType: "$",
  deposit: 0,
  discountType: "$",
  discount: 0,
  notes: "",
  status: "draft",
  signature: null,
  days_valid: 30,
  version: "v4",
  url: {
    original: "",
    short: "",
    short_id: "",
  },
}

const invoice = {
  createdAt: 0,
  id: "",
  reference_number: 0,
  type: "general",
  user: {
    uid: "",
    name: "",
  },
  company: {id: ""},
  company_id: "",
  contact: contact,
  labor: [],
  products: [],
  attachments: [],
  photos: {},
  total: 0,
  depositType: "$",
  deposit: 0,
  discountType: "$",
  discount: 0,
  days_until_due: 7,
  notes: "",
  status: "draft",
  version: "v4",
  url: {
    original: "",
    short: "",
    short_id: "",
  },
  stripe: {
    id: "",
    hosted_invoice_url: "",
    invoice_pdf: "",
  },
}

const statuses = ["draft", "pending", "accepted", "complete", "canceled"]

const lead = {
  createdAt: 0,
  id: "",
  company: {id: ""},
  contact,
  message: "",
  status: "pending",
  version: "v4",
}

const calendar_event = {
  id: "",
  uid: "",
  companyId: "",
  subContractorId: "",
  estimateId: "",
  title: "",
  details: "",
  date: "",
  endDate: "",
  time: "",
  endTime: "",
  allDay: false,
  dates: [], // "2023-10-10"
  type: {
    label: "",
    value: "",
  },
  contact,
  user: {name: ""},
  company: {
    email: "",
    phone: "",
    name: "",
  },
}

const notification = {
  id: "",
  companyId: "",
  title: "",
  body: "",
  href: "",
  badge: 1,
  createdAt: 0,
  devices: [],
  docId: "",
  type: "",
}

const colors = [
  "red",
  "orange",
  "amber",
  "yellow",
  "lime",
  "green",
  "emerald",
  "teal",
  "cyan",
  "sky",
  "blue",
  "indigo",
  "violet",
  "purple",
  "fuchsia",
  "pink",
  "rose",
]

const bgColors = [
  "bg-red-500",
  "bg-orange-500",
  "bg-amber-500",
  "bg-yellow-500",
  "bg-lime-500",
  "bg-green-500",
  "bg-emerald-500",
  "bg-teal-500",
  "bg-cyan-500",
  "bg-sky-500",
  "bg-blue-500",
  "bg-indigo-500",
  "bg-violet-500",
  "bg-purple-500",
  "bg-fuchsia-500",
  "bg-pink-500",
  "bg-rose-500",
]

const currency_options = ["USD", "CAD", "MXN", "AUD", "NZD", "EUR"]
const country_options = ["US", "CA", "AU", "NZ"]
const due_options = [0, 7, 14, 30, 60, 90]
const valid_options = [14, 30, 60, 90, 180, 365]

export {
  attachment,
  address,
  company,
  contact,
  directory,
  estimate,
  invoice,
  calendar_event,
  production_rate,
  exterior_template,
  interior_template,
  email_template,
  lineItem,
  defaultLineItems,
  room,
  rooms,
  statuses,
  notification,
  user,
  tax,
  lead,
  structure_options,
  position_options,
  rate_options,
  service_options,
  currency_options,
  country_options,
  due_options,
  valid_options,
  colors,
  bgColors,
  createProductionRate,
  createPaintingTemplate,
}
