import {acceptHMRUpdate, defineStore} from "pinia"
import {cloneDeep} from "lodash"
import {Capacitor} from "@capacitor/core"
import {Keyboard} from "@capacitor/keyboard"
import {StatusBar} from "@capacitor/status-bar"
import {Badge} from "@capawesome/capacitor-badge"
import {SplashScreen} from "@capacitor/splash-screen"
import {NavigationBar} from "@hugotomazi/capacitor-navigation-bar"
import {usePlatform} from "@/composables/usePlatform"
import {useIAPStore} from "@/stores/useIAPStore.js"
import {useUserStore} from "@/stores/useUserStore.js"
import {useGlobalStore} from "@/stores/useGlobalStore.js"
import {useDeviceStore} from "@/stores/useDeviceStore.js"
import {useInvoiceStore} from "@/stores/useInvoiceStore.js"
import {useContactStore} from "@/stores/useContactStore.js"
import {useEstimateStore} from "@/stores/useEstimateStore.js"
import {useResourceStore} from "@/stores/useResourceStore.js"
import {useNotificationStore} from "@/stores/useNotificationStore.js"
import {company as companyRef, user as userRef} from "@/utils"
import {firebaseAuth, firebaseMessage, functions, httpsCallable} from "@/apis"
import {
  createUserWithEmailAndPassword,
  deleteUser,
  EmailAuthProvider,
  onAuthStateChanged,
  reauthenticateWithCredential,
  sendEmailVerification,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth"
import {useRouter} from "vue-router"
import {useDark, useStorage} from "@vueuse/core"
const {isCapacitor} = usePlatform

export const useAuthStore = defineStore("auth", () => {
  // Utils
  const router = useRouter()

  // Pinia Stores
  const useIAP = useIAPStore()
  const userStore = useUserStore()
  const deviceStore = useDeviceStore()
  const globalStore = useGlobalStore()
  const contactStore = useContactStore()
  const invoiceStore = useInvoiceStore()
  const resourceStore = useResourceStore()
  const estimateStore = useEstimateStore()
  const notificationStore = useNotificationStore()

  // State
  const isAuthorized = useStorage("isAuthorized", false)

  // Actions
  /**
   * Asynchronously creates a new user and company in Firebase using the given email, password, and user and company
   * data.
   * @param {string} email - The email address to use for the new user and company.
   * @param {string} password - The password to use for the new user account.
   * @returns {Promise<undefined>} A promise that resolves with undefined when the operation is complete.
   */
  async function createUserAndCompany(email, password) {
    try {
      const result = await createUserWithEmailAndPassword(firebaseAuth, email, password)
      const uid = result.user.uid
      const userEmail = email.toLowerCase()

      // Assign data to user
      const user = cloneDeep(userRef)
      user.uid = uid
      user.myCompany = uid
      user.email = userEmail
      user.platform.platform = usePlatform?.platform || "web"
      user.platform.capacitor = usePlatform.isCapacitor || false

      // Assign data to company
      const company = cloneDeep(companyRef)
      company.contact.email = userEmail
      company.id = uid
      company.admin.push(uid)
      company.version = "v4"
      userStore.company = company

      const callable = httpsCallable(functions, "firebaseCreateUserAndCompany")
      await callable({user, company})

      userStore.user = user
      userStore.company = company
      isAuthorized.value = true
      await router.push("/")
      globalStore.openDialog(
        "success",
        "Welcome to PaintBox!",
        "To get started, personalize your profile and then create your first estimate. I recommend estimating a familiar area to dial in production rates that scale.\n\n Have a question or need help? Check out the video tutorials and other resources on the support page.",
        {name: "Update Profile", action: () => router.push("settings/account")},
        {name: "Explore PaintBox", action: () => {}}
      )
      globalStore.loadingNotify.show = false
    } catch (e) {
      globalStore.openNotify("error", "Error Creating Account", firebaseMessage(e))
      throw new Error(e)
    }
  }

  /**
   * Asynchronously signs in a user using the given email and password.
   * @async
   * @function
   * @param {string} email - The email address to use for the sign-in.
   * @param {string} password - The password to use for the sign-in.
   * @param {boolean} reauthenticate - The firebase auth provider to sign in with.     * @returns {Promise<{uid: *}>}
   *   A promise that resolves with undefined when the sign-in is complete.
   */
  async function signInUser(email, password, reauthenticate) {
    try {
      if (reauthenticate) {
        await reauthenticateUser(password)
      } else {
        await signInWithEmailAndPassword(firebaseAuth, email, password)
      }
      isAuthorized.value = true
      return {success: true}
    } catch (e) {
      globalStore.openNotify("error", "Error Signing in", firebaseMessage(e))
      throw new Error(e)
    }
  }

  async function reauthenticateUser(password) {
    try {
      const user = firebaseAuth.currentUser
      const credential = EmailAuthProvider.credential(user.email, password)
      await reauthenticateWithCredential(user, credential)
    } catch (e) {
      throw new Error(e)
    }
  }

  /**
   * Asynchronously signs out the current user.
   */
  async function signOutUser() {
    try {
      await unsubscribeListeners()
      await signOut(firebaseAuth)
      isAuthorized.value = false
      await router.push("/sign-in")
    } catch (e) {
      console.error("error", e)
      globalStore.openNotify("error", "Error Signing out", firebaseMessage(e.message))
    }
  }

  async function unsubscribeListeners() {
    const stores = [
      userStore, // Assuming these stores are previously defined objects
      invoiceStore,
      notificationStore,
      deviceStore,
      globalStore,
      contactStore,
      resourceStore,
      estimateStore,
    ]

    // Use Promise.allSettled to handle both fulfilled and rejected promises
    const results = await Promise.allSettled(
      stores.map(async (store) => {
        try {
          // Unsubscribe listeners if any
          if (store?.listeners?.length) {
            for (const unsubscribe of store.listeners) {
              await unsubscribe()
            }
          }

          // Reset store if possible
          if (store.$reset) {
            await store.$reset()
          }
        } catch (error) {
          // Handle the error, possibly by logging or rethrowing with additional context
          console.error(`Error handling store: ${store.name || "Unnamed store"}`, error)
          // Optionally, rethrow the error if you want to handle it further up the chain
          throw new Error(`Failed to handle ${store.name || "Unnamed store"}: ${error.message}`)
        }
      })
    )

    // Process results to handle any errors
    results.forEach((result, index) => {
      if (result.status === "rejected") {
        console.error(`Failed to unsubscribe/reset store at index ${index}: ${result.reason}`)
        // Additional error handling logic can be placed here
      }
    })
  }

  /**
   * Asynchronously sends a password reset email to the given email address.
   * @async
   * @function
   * @param {string} email - The email address to send the password reset email to.
   * @returns {Promise<undefined>} A promise that resolves with undefined when the password reset email is sent.
   */
  async function resetPassword(email) {
    try {
      await sendPasswordResetEmail(firebaseAuth, email)
      globalStore.openNotify(
        "success",
        "Success",
        "Please check your email for password reset instructions."
      )
    } catch (error) {
      globalStore.openNotify(
        "error",
        "Error Resetting Password",
        "There was an issue. Please check your email and try again."
      )
    }
  }

  async function verifyEmail() {
    try {
      await sendEmailVerification(firebaseAuth.currentUser)
      globalStore.openNotify("success", "Success", "Verify your account via email.")
    } catch (e) {
      globalStore.openNotify("error", "Error", "Try again later.")
    }
  }

  async function deleteAccount(password, url) {
    try {
      globalStore.loadingNotify.show = true
      globalStore.loadingNotify.title = "Deleting Account"

      await signInUser("", `${password}`, true)

      // if website delete url from master list
      if (url) {
        const callable = httpsCallable(functions, "firebaseDeleteWebsiteUrl")
        await callable({url})
      }

      // then delete all user data
      const user = firebaseAuth.currentUser
      await unsubscribeListeners()
      await deleteUser(user)
      await signOut(firebaseAuth)

      // provide notification
      await router.push("/deleted-account")
    } catch (e) {
      console.error("error", e)
      //  or error notification
      globalStore.openNotify(
        "error",
        "Error",
        "There was an issue deleting your data. Please try again."
      )
    } finally {
      globalStore.loadingNotify.show = false
      globalStore.loadingNotify.title = "Loading"
    }
  }

  /**
   * Sets up a listener to the Firebase authentication state and sets the 🍍Pinia state, as well as fetching and updating relevant data in the 🍍Pinia stores.
   * @function
   * @returns {undefined} Returns undefined.
   */
  async function handleAuthStateChange() {
    onAuthStateChanged(firebaseAuth, async (user) => {
      if (usePlatform.isCapacitor) {
        await deviceStore.enableFirestoreNetwork()
      }

      if (!user) {
        console.log("UNAUTHORIZED USER")
        return
      }

      const userUid = user.uid

      // Fetch user/company data
      let userData
      try {
        userData = await userStore.fetchUserProfile(userUid)
        if (userData?.uid) {
          if (["/sign-up", "/sign-in"].includes(router.currentRoute.value.fullPath)) {
            await router.push("/")
          }
        } else {
          if (["/sign-in"].includes(router.currentRoute.value.fullPath)) {
            globalStore.openNotify(
              "error",
              "Error signing in",
              "No account found associated with this email address. Please try signing in with another account or create a new PaintBox account."
            )
            return
          }
        }
      } catch (e) {
        await router.push("/sign-up")
        throw new Error("User profile not found: " + e)
      }

      isAuthorized.value = !!user && !!userData?.uid

      if (!userStore.company.id) {
        await userStore.fetchCompanyProfile(userUid)
      }

      // Estimates - this year
      const year = new Date().getFullYear()
      await estimateStore.fetchEstimatesByYear(userUid, year)

      // Invoices
      if (!invoiceStore?.invoices.size) {
        await invoiceStore.fetchInvoices(userUid)
      }

      // Contacts
      if (!contactStore.contacts.size) {
        await contactStore.fetchContacts(userUid)
      }

      // Attachments
      if (!resourceStore.attachments.size) {
        await resourceStore.fetchAttachments(userUid)
      }

      // Email templates
      if (!resourceStore.emailTemplates.size) {
        await resourceStore.fetchEmailTemplates(userUid)
      }

      // Taxes
      if (!resourceStore.taxItems.size) {
        await resourceStore.fetchTaxItems(userUid)
      }

      // Today's events
      if (!resourceStore.todaysEvents.size) {
        await resourceStore.fetchTodaysEvents(userUid)
      }

      // Fetch notifications
      if (!notificationStore.notifications.size) {
        await notificationStore.fetchNotifications(userUid)
      }

      // Line items
      if (!resourceStore.lineItems.size) {
        await resourceStore.fetchLineItems(userUid)
      }

      globalStore.loadingNotify.show = false

      // handle capacitor device
      if (isCapacitor) {
        await SplashScreen.hide()
        await useIAP.initProducts()
        await notificationStore.registerNotifications()
        setTimeout(async () => {
          await setMobileAccessories()
        }, 500)
        const {isSupported} = await Badge.isSupported()
        const count = notificationStore.notifications?.size
        if (isSupported) await Badge.set({count})
      }
    })
  }

  async function setMobileAccessories() {
    const isDark = useDark()
    const darkMode = isDark.value
    const accessory = darkMode
      ? {style: "DARK", color: "#030712"}
      : {style: "LIGHT", color: "#ffffff"}

    if (Capacitor.getPlatform() === "android") {
      await StatusBar.setStyle({style: accessory.style})
      await StatusBar.setBackgroundColor({color: accessory.color})
      await NavigationBar.setColor({color: accessory.color, darkButtons: !darkMode})
    }

    if (Capacitor.getPlatform() === "ios") {
      await StatusBar.setStyle({style: accessory.style})
      await Keyboard.setScroll({isDisabled: true})
      await Keyboard.setStyle({style: accessory.style})
      await Keyboard.setAccessoryBarVisible({isVisible: false})
    }
  }

  return {
    isAuthorized,
    deleteAccount,
    createUserAndCompany,
    signInUser,
    signOutUser,
    reauthenticateUser,
    resetPassword,
    verifyEmail,
    handleAuthStateChange,
    setMobileAccessories,
    unsubscribeListeners,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useAuthStore, import.meta.hot))
}
