import {createApp} from "vue"
import {createPinia} from "pinia"
import "@/assets/style.css"
import "@/assets/tailwind.css"
import App from "@/App.vue"
import router from "@/router"
import {createHead} from "@unhead/vue"

const app = createApp(App)
const pinia = createPinia()
const head = createHead()

// app.use(GoogleMaps)
app.use(pinia)
app.use(head)
app.use(router)
app.mount("#app")
